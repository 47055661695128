import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="48" height="48" rx="4" fill="url(#paint0_linear_1_2056)" fillOpacity="0.1" />
            <path d="M20 32L28 24L20 16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_1_2056" x1="0" y1="24" x2="46.8618" y2="31.3033" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C957FF" />
                    <stop offset="1" stopColor="#8100BE" />
                </linearGradient>
            </defs>
        </Svg>
    );
};

export default Icon;