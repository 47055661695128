import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const NoEvents: React.FC<SvgProps> = (props) => {
    return (
        <Svg
            {...props}
            width="160" height="178" viewBox="0 0 160 178"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path opacity="0.2" d="M80 168.535C124.183 168.535 160 132.718 160 88.5352C160 44.3524 124.183 8.53516 80 8.53516C35.8172 8.53516 0 44.3524 0 88.5352C0 132.718 35.8172 168.535 80 168.535Z" fill="url(#paint0_linear_3386_13786)" />
            <path d="M125.864 54.4023H34.1302C31.1847 54.4023 28.7969 56.7902 28.7969 59.7357V171.736C28.7969 174.681 31.1847 177.069 34.1302 177.069H125.864C128.809 177.069 131.197 174.681 131.197 171.736V59.7357C131.197 56.7902 128.809 54.4023 125.864 54.4023Z" fill="white" />
            <path d="M69.3318 70.4023H41.5984C39.8311 70.4023 38.3984 71.835 38.3984 73.6023C38.3984 75.3697 39.8311 76.8023 41.5984 76.8023H69.3318C71.0991 76.8023 72.5318 75.3697 72.5318 73.6023C72.5318 71.835 71.0991 70.4023 69.3318 70.4023Z" fill="#D0D5DD" />
            <path d="M88.5318 84.2695H41.5984C39.8311 84.2695 38.3984 85.7022 38.3984 87.4695C38.3984 89.2368 39.8311 90.6695 41.5984 90.6695H88.5318C90.2991 90.6695 91.7318 89.2368 91.7318 87.4695C91.7318 85.7022 90.2991 84.2695 88.5318 84.2695Z" fill="#EAECF0" />
            <path d="M69.3318 99.2051H41.5984C39.8311 99.2051 38.3984 100.638 38.3984 102.405C38.3984 104.172 39.8311 105.605 41.5984 105.605H69.3318C71.0991 105.605 72.5318 104.172 72.5318 102.405C72.5318 100.638 71.0991 99.2051 69.3318 99.2051Z" fill="#D0D5DD" />
            <path d="M88.5318 113.072H41.5984C39.8311 113.072 38.3984 114.505 38.3984 116.272C38.3984 118.04 39.8311 119.472 41.5984 119.472H88.5318C90.2991 119.472 91.7318 118.04 91.7318 116.272C91.7318 114.505 90.2991 113.072 88.5318 113.072Z" fill="#EAECF0" />
            <path d="M69.3318 128.002H41.5984C39.8311 128.002 38.3984 129.435 38.3984 131.202C38.3984 132.969 39.8311 134.402 41.5984 134.402H69.3318C71.0991 134.402 72.5318 132.969 72.5318 131.202C72.5318 129.435 71.0991 128.002 69.3318 128.002Z" fill="#D0D5DD" />
            <path d="M88.5318 141.869H41.5984C39.8311 141.869 38.3984 143.302 38.3984 145.069C38.3984 146.836 39.8311 148.269 41.5984 148.269H88.5318C90.2991 148.269 91.7318 146.836 91.7318 145.069C91.7318 143.302 90.2991 141.869 88.5318 141.869Z" fill="#EAECF0" />
            <path d="M125.864 0H34.1302C31.1847 0 28.7969 2.38781 28.7969 5.33333V37.3333C28.7969 40.2789 31.1847 42.6667 34.1302 42.6667H125.864C128.809 42.6667 131.197 40.2789 131.197 37.3333V5.33333C131.197 2.38781 128.809 0 125.864 0Z" fill="#CD63FF" />
            <path d="M69.3318 11.7344H41.5984C39.8311 11.7344 38.3984 13.1671 38.3984 14.9344C38.3984 16.7017 39.8311 18.1344 41.5984 18.1344H69.3318C71.0991 18.1344 72.5318 16.7017 72.5318 14.9344C72.5318 13.1671 71.0991 11.7344 69.3318 11.7344Z" fill="#EAECF0" />
            <path d="M88.5318 25.6016H41.5984C39.8311 25.6016 38.3984 27.0343 38.3984 28.8016C38.3984 30.5689 39.8311 32.0016 41.5984 32.0016H88.5318C90.2991 32.0016 91.7318 30.5689 91.7318 28.8016C91.7318 27.0343 90.2991 25.6016 88.5318 25.6016Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_3386_13786" x1="0" y1="88.5352" x2="156.206" y2="112.879" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C957FF" />
                    <stop offset="1" stopColor="#8100BE" />
                </linearGradient>
            </defs>
        </Svg>
    );
};

export default NoEvents;
