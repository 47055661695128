import {
    Avatar,
    ChangePasswordIcon,
    // HistoryIcon,
    InviteFriendIcon,
    LogoutIcons,
    // ProfileDetailIcon,
    ProfileIcon,
    // SettingsIcon,
} from "components/Svg";
import {
    // NavBarContainer,
    LogoutIcon, ProfileMenu,
    // RouterATag
} from "./styles";
import { useUserManager } from "state/user/hooks";
import { useModal } from "widgets/Modal";
import { ChangePasswordModal } from 'views/Home/components/forms/components/ChangePasswordModal';
import { useHistory } from "react-router-dom";

interface INavbarSettings {
    toggleMenu: () => void;
    isWeb: boolean;
}
const NavbarSettings = (props: INavbarSettings) => {
    //For Profile Modal
    // const [showProfileModal, onProfileDismiss] = useModal(
    //   <ProfileModal handleClose={() => onProfileDismiss()} />,
    //   true
    // );
    //For Settings Modal
    // const [showSettingModal, onSettingDismiss] = useModal(
    //     <SettingsModal handleClose={() => onSettingDismiss()} />,
    //     true
    // );
    //For ChangePassword Modal
    const [showChangePasswordModal, onChangeasswordDismiss] = useModal(
        <ChangePasswordModal handleClose={() => onChangeasswordDismiss()} />,
        true
    );
    const [user, setUser] = useUserManager();
    const history = useHistory();
    return (
        <>
            {user && (
                <LogoutIcon>
                    <LogoutIcon.Toggle
                        type="button"
                        variant="primary"
                        className="logoutButton"
                        style={{ maxWidth: '15rem', width: '2rem 0rem' }}
                    >
                        <ProfileIcon width="32px" className="fillTransparent" />
                    </LogoutIcon.Toggle>
                    <LogoutIcon.Menu align="end">
                        {/* //:TODO: Add Profile Modal and Settings Modal in the future. */}
                        {/* <LogoutIcon.Item >
                      <ProfileDetailIcon
                        width="32px"
                        className="fillTransparent"
                      />
                      <span
                        onClick={() => {
                          if (user) {
                            showProfileModal();
                          }
                        }}
                      >Profile Details</span>
                    </LogoutIcon.Item> */}
                        {/* <LogoutIcon.Item onClick={() => {
                                            toggleMenu();
                                            if (user) {
                                                showSettingModal();
                                            }
                                        }}>
                                            <SettingsIcon width="32px" className="fillTransparent" />
                                            <span>Settings</span>
                                        </LogoutIcon.Item> */}
                        {
                            user && props.isWeb && <>
                                <ProfileMenu>
                                    <Avatar className="fillTransparent" />
                                    <span>{user.email}</span>
                                </ProfileMenu>
                                <hr />
                            </>
                        }
                        <LogoutIcon.Item onClick={() => {
                            if (user) {
                                showChangePasswordModal();
                            }
                        }}>
                            <ChangePasswordIcon
                                width="32px"
                                className="fillTransparent"
                            />
                            <span>Change Password</span>
                        </LogoutIcon.Item>
                        {/* //:TODO: Add History Menu in the future. */}
                        {/* <LogoutIcon.Item >
                      <HistoryIcon width="32px" className="fillTransparent" />
                      <span>History</span>
                    </LogoutIcon.Item> */}
                        <LogoutIcon.Item onClick={() => {
                            if (user) {
                                history.push("/referral");
                            }
                        }}>
                            <InviteFriendIcon
                                width="32px"
                                className="fillTransparent"
                            />
                            <span>Invite Friends</span>
                        </LogoutIcon.Item>
                        <LogoutIcon.Item onClick={() => { setUser(null); localStorage.clear(); }}>
                            <LogoutIcons width="32px" className="fillTransparent" />
                            <span>Logout</span>
                        </LogoutIcon.Item>
                    </LogoutIcon.Menu>
                </LogoutIcon>
            )
            }
        </>
    );
};
export default NavbarSettings;