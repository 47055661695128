import axios from "axios";
import { REACT_APP_APIS } from "config/constants/endpoints";
import store from "state";
import { setUser } from "state/user/actions";

const Axios = axios.create({
	baseURL: REACT_APP_APIS,
});

// Add a response interceptor
Axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		const message = error.response?.data?.code || error.message;
		if (message === 401) {
			store.dispatch(setUser(null));
			localStorage.clear();
		}
		return Promise.reject(error);
	}
);

export default Axios;
