import { Urls } from './types'
export const lightUrls:Urls = {
    hamburger: './assets/images/mobile-icon.png',
    gamesfiBackground: './assets/images/gamesfi-background.png',
    gameDevice: './assets/images/game-device.png',
    footerBackground: './assets/images/footer-background.svg'
}
export const darkUrls:Urls = {
    hamburger: './assets/images/hamburger-icon-dark.svg',
    gamesfiBackground: './assets/images/gamesfi-background.png',
    gameDevice: './assets/images/game-device.png',
    footerBackground: './assets/images/footer-background.svg'
}