import { ApplicationRoutes } from "./types";
import { lazy } from "react";
const Home = lazy(() => import("views/Home"));
const Games = lazy(() => import("views/Games"));
const GameCategory = lazy(() => import("views/Games/components/GameCategory"));
const GameDetails = lazy(() => import("views/Games/components/GameDetails"));
const ForgetPassword = lazy(() => import("views/ForgetPassword"));
const Withdraw = lazy(() => import("views/Home/components/withdraw"));
const Referral = lazy(() => import("views/Home/components/referral"));
const GamePlay = lazy(() => import("views/Games/components/GamePlay"));
const ContactUs = lazy(() => import("components/Footer/components/ContactUs"));
const EventHistory = lazy(() => import("views/Home/components/EventsHistory"))
const LeaderBoard = lazy(() => import("views/Home/components/leaderboard"))
const HowToRedeem = lazy(() => import("views/Home/components/howToRedeem/HowToRedeem"))
export const routes = [
  {
    route: ApplicationRoutes.HOME,
    component: Home,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  },
  {
    route: ApplicationRoutes.GAMES,
    component: Games,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: true,
  },
  {
    route: ApplicationRoutes.GAMEPLAY,
    component: GamePlay,
    exact: true,
    isHeaderShow: false,
    isFooterShow: false,
    isAuth: true,
  },
  {
    route: ApplicationRoutes.GAMECATEGORY,
    component: GameCategory,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: true,
  },
  {
    route: ApplicationRoutes.GAMEDETAILS,
    component: GameDetails,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  },
  {
    route: ApplicationRoutes.FORGOTPASSWORD,
    component: ForgetPassword,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  },
  {
    route: ApplicationRoutes.WITHDRAW,
    component: Withdraw,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: true,
  },
  {
    route: ApplicationRoutes.REFERRAL,
    component: Referral,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: true,
  },
  {
    route: ApplicationRoutes.CONTACT,
    component: ContactUs,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: true,
  },
  {
    route: ApplicationRoutes.EVENTSHISTORY,
    component: EventHistory,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  },
  {
    route: ApplicationRoutes.EVENTS,
    component: LeaderBoard,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  },
  {
    route: ApplicationRoutes.HOW_TO_REDEEM,
    component: HowToRedeem,
    exact: true,
    isHeaderShow: true,
    isFooterShow: true,
    isAuth: false,
  }
];
