import { Modal } from "widgets/Modal";
import { FormLogoHeader } from "views/Home/components/forms/components/FormHeader";
import { FormSubHeading } from "views/Home/components/forms/styles";

export const ContactModal = (props) => {

    const { handleClose } = props;

    return (
        <Modal
            onDismiss={() => {
                handleClose();
            }}
            title={""}
            minWidth={"500px"}
        >
            <FormLogoHeader />

            <FormSubHeading>
                You have successfully submitted the form and our team will get back to you within 24 hours.
            </FormSubHeading>
        </Modal>
    );
};
