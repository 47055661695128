import { DefaultTheme } from "styled-components";
import base from "./base";
import { darkColors } from "./colors";
import { darkUrls } from './urls'

const darkTheme: DefaultTheme = {
  ...base,
  colors: darkColors,
  isDark: true,
  url: darkUrls
};

export default darkTheme;