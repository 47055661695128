import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const YellowDot: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx="6" cy="6" r="6" fill="#F6CE69" />
        </Svg>
    )
}
export default YellowDot
