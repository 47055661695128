import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const FacebookIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M8.16663 11.6667V16.3333H11.6666V24.5H16.3333V16.3333H19.8333L21 11.6667H16.3333V9.33333C16.3333 9.02391 16.4562 8.72717 16.675 8.50838C16.8938 8.28958 17.1905 8.16667 17.5 8.16667H21V3.5H17.5C15.9529 3.5 14.4691 4.11458 13.3752 5.20854C12.2812 6.30251 11.6666 7.78624 11.6666 9.33333V11.6667H8.16663Z" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
        </Svg>
    )
}
export default FacebookIcon
