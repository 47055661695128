import {
  Input,
  // SocialBody,
  FormHeading,
  FormCheckBox,
  FormButton,
  CenterDiv,
  FormSubHeading,
  ModalButton,
  PasswordShowHide,
  HideShowIcon,
  CheckboxWrapper,
  SignuPolicy,
} from "../styles";
// import FbLogo from "assets/images/form/facebook.svg"
// import GoogleLogo from "assets/images/form/google.svg"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Modal, useModal } from "widgets/Modal";
import { FormLogoHeader } from "./FormHeader";
import { LoginModal } from "./LoginModal";
import { CheckBox } from "components/CheckBox";
import { registerUser } from "../../../../../state/user/post/registerUser";
import { useUserManager } from "state/user/hooks";
import { IUser, ErrorData } from "state/user/types";
import { useState } from "react";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import Spinner from "components/Spinner";
import { EyeIconHidden, EyeIconShow } from "components/Svg";
import { FormErrorMessage, RealTimeMessage } from "components/Footer/styles";
import { Box, Flex } from "components/Box";
import { AxiosError } from "axios";
import { EMAIL_VALIDATION, PASSWORD_VALIDATION, PASSWORD_MESSAGE } from "config/constants/endpoints";
interface Inputs {
  username: string;
  email: string;
  password: string;
  privacypolicy: boolean;
  ConfirmPassword: string;
  TermsandConditions: string;
  referred_by: string;
  show: boolean;
  onHide?: () => void;
}

export const SignupModal = (props) => {
  const emailValidation = EMAIL_VALIDATION;
  const passwordValidation = PASSWORD_VALIDATION;
  const [showLoginModal, onLoginDismiss] = useModal(
    <LoginModal handleClose={() => onLoginDismiss()} />,
    true
  );

  const { handleClose } = props;
  const [resError, setResError] = useState("");
  const [, setUser] = useUserManager();
  const [validUser, setValidUser] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Inputs>({
    mode: "onChange" || "onSubmit",
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoader(true);
      const singleuser: IUser = await registerUser(
        data?.email,
        data?.password,
        data?.referred_by
      );
      if (singleuser) {
        setLoader(false);
        setUser(singleuser);
        handleClose();
        setValidUser(false);
        reset();
      }
    } catch (error) {
      const errormessage = (error as AxiosError).response.data;
      setResError((errormessage as ErrorData).message);
      setLoader(false);
      setValidUser(true);
    }
  };
  // const responseFacebook = (response) => {
  // }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <Modal onDismiss={handleClose} title={""} minWidth={"500px"}>
      <FormLogoHeader />

      <FormHeading>Signup to your account</FormHeading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <RealTimeMessage>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            {...register("email", {
              required: "*Email is Required.",
              pattern: {
                value: emailValidation,
                message: "Invalid Email Address",
              },
            })}
            className={errors.email ? "invalid" : ""}
            style={{ marginBottom: "0rem" }}
            onFocus={() => setValidUser(false)}
          />
          <Flex>
            {validUser && <FormErrorMessage />}
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </Flex>
        </RealTimeMessage>

        <RealTimeMessage>
          <PasswordShowHide>
            <Input
              type={passwordShown ? "text" : "password"}
              placeholder="Password"
              {...register("password", {
                required: "*Password is Required.",
                pattern: {
                  value: passwordValidation,
                  message: PASSWORD_MESSAGE
                }
              })}
              className={errors.password ? "invalid" : ""}
              style={{ marginBottom: "0rem" }}
              onBlur={(e: any) => setValue("password", e.target.value.trim())}
            />
            <HideShowIcon
              onClick={togglePassword}
              className="hideIcon"
              type="button"
            >
              {passwordShown ? <EyeIconHidden /> : <EyeIconShow />}
            </HideShowIcon>
          </PasswordShowHide>

          <Flex>
            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </Flex>
        </RealTimeMessage>

        <RealTimeMessage>
          <Box>
            <Controller
              control={control}
              name="referred_by"
              render={({ field }) => {
                return (
                  <Input
                    type="text"
                    placeholder="Referral Code (optional)"
                    className={errors.referred_by ? "invalid" : ""}
                    style={{ marginBottom: "0.5rem" }}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value.trim());
                    }}
                  />
                );
              }}
            />
          </Box>
        </RealTimeMessage>

        <FormCheckBox className="mt-5">
          <CheckboxWrapper className="w-100">
            <Box className="d-flex w-100">
              <CheckBox
                label={<FormSubHeading style={{ marginLeft: "1rem" }}>
                  I accept the{" "}
                  <SignuPolicy
                    href={`${process.env?.REACT_APP_PRIVACY_POLICY_LINK}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </SignuPolicy>
                </FormSubHeading>}
                type="checkbox"
                className="w-100"
                {...register("privacypolicy", {
                  required: "*Field is Required.",
                })}
              />
            </Box>
          </CheckboxWrapper>
          <Box>
            <FormErrorMessage>{errors.privacypolicy?.message}</FormErrorMessage>
            <FormErrorMessage>{resError}</FormErrorMessage>
          </Box>
        </FormCheckBox>

        <FormButton type="submit" className="mt-3">
          {loader ? <Spinner height="40px" /> : "SIGN UP"}
        </FormButton>
      </form>

      {/* <SocialBody>
        <GoogleLogin
          clientId="808346269769-qj7p1etrq333s6ilc4m1f6vcvja31e2j.apps.googleusercontent.com"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              className="fb-btn "
              disabled={renderProps.disabled}
            >
              {" "}
              <img src={GoogleLogo} alt="" /> Google
            </button>
          )}
          buttonText="Login"
          onSuccess={responseFacebook}
          cookiePolicy={"single_host_origin"}
          className="connect-fb active"
          fetchBasicProfile={true}
        />
        <FacebookLogin
          appId="5374180382645493"
          autoLoad
          callback={responseFacebook}
          render={renderProps => (
            <button className="fb-btn" onClick={renderProps.onClick}>
              <img src={FbLogo} alt="" />
              Facebook
            </button>
          )}
        />
      </SocialBody> */}

      <CenterDiv>
        <FormSubHeading>
          Already have an account?
          <ModalButton onClick={() => showLoginModal()} type="button">
            {" "}
            Login
          </ModalButton>
        </FormSubHeading>
      </CenterDiv>
    </Modal>
  );
};
