import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const TelegramIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M17.5 11.6667L12.8333 16.3333L19.8333 23.3333L24.5 4.66666L3.5 12.8333L8.16667 15.1667L10.5 22.1667L14 17.5" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
        </Svg>
    )
}
export default TelegramIcon
