import { createReducer } from "@reduxjs/toolkit";
import { updateVersion } from "../global/actions";
import { toggleTheme, setUser, setUserBalance, setEventName } from "./actions";
import { IUser } from "./types";

const currentTimestamp = () => new Date().getTime();

export interface UserState {
	// the timestamp of the last updateVersion action
	lastUpdateVersionTimestamp?: number;
	timestamp: number;
	isDark: boolean;
	info: IUser;
	balance: number;
	eventname: string;
}

export const initialState: UserState = {
	timestamp: currentTimestamp(),
	isDark: false,
	info: null,
	balance: 0,
	eventname: "Event"
};

export default createReducer(initialState, (builder) =>
	builder
		.addCase(setUser, (state, { payload }) => {
			state.info = payload;
		})
		.addCase(updateVersion, (state) => {
			// noinspection SuspiciousTypeOfGuard
			state.lastUpdateVersionTimestamp = currentTimestamp();
		})
		.addCase(toggleTheme, (state) => {
			state.isDark = !state.isDark;
		})
		.addCase(setUserBalance, (state, { payload }) => {
			state.balance = payload;
		})
		.addCase(setEventName, (state, { payload }) => {
			state.eventname = payload;
		})
);
