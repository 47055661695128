import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const ReferralIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect opacity="0.2" width="40" height="40" fill="url(#paint0_linear_2685_9933)" />
            <g clipPath="url(#clip0_2685_9933)">
                <path d="M28.0013 14.666H17.3346C15.8619 14.666 14.668 15.8599 14.668 17.3327V27.9993C14.668 29.4721 15.8619 30.666 17.3346 30.666H28.0013C29.4741 30.666 30.668 29.4721 30.668 27.9993V17.3327C30.668 15.8599 29.4741 14.666 28.0013 14.666Z" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
                <path d="M25.332 14.6673V12.0007C25.332 11.2934 25.0511 10.6151 24.551 10.115C24.0509 9.61494 23.3726 9.33398 22.6654 9.33398H11.9987C11.2915 9.33398 10.6132 9.61494 10.1131 10.115C9.61298 10.6151 9.33203 11.2934 9.33203 12.0007V22.6673C9.33203 23.3746 9.61298 24.0528 10.1131 24.5529C10.6132 25.053 11.2915 25.334 11.9987 25.334H14.6654" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2685_9933" x1="-658" y1="1357.6" x2="2220.4" y2="1357.6" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#C957FF" />
                    <stop offset="1" stopColor="#8100BE" />
                </linearGradient>
                <clipPath id="clip0_2685_9933">
                    <rect width="32" height="32" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </Svg>
    )
}
export default ReferralIcon
