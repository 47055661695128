import { Colors } from "./types";

export const baseColors = {
  failure: "#ED4B9E",
  primary: "#1FC7D4",
  primaryBright: "#53DEE9",
  primaryDark: "#0098A1",
  secondary: "#7645D9",
  success: "#31D0AA",
  warning: "#FFB237",
  error: "red",
};

export const brandColors = {
  binance: "#F0B90B",
};

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: "#FAF9FA",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#FFFFFF",
  text: "#452A7A",
  textDisabled: "#BDC2C4",
  textSubtle: "#8f80ba",
  borderColor: "#E9EAEB",

  textPrimary: "#FFFFFF",
  textSecondary: "#CECECE",
  textTertiary: "rgba(205, 99, 255, 1)",
  headerBackground: "#050C20",
  footerBackground: "rgba(205, 99, 255, 0.1)",
  footerLinksColor: "rgba(205, 99, 255, 0.2)",
  hrColor: "rgba(255, 255, 255, 0.5)",

  error: "red",
  modalBackground: "#200942",
  totalPages: "#94A3B8",
};

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  secondary: "#9A6AFF",
  background: "#100C18",
  backgroundDisabled: "#3c3742",
  backgroundAlt: "#27262c",
  primaryDark: "#0098A1",
  text: "#452A7A",
  textDisabled: "#BDC2C4",
  textSubtle: "#8f80ba",
  borderColor: "#E9EAEB",
  error: "red",
  textPrimary: "#FFFFFF",
  textSecondary: "#CECECE",
  textTertiary: "rgba(205, 99, 255, 1)",
  headerBackground: "#050C20",
  footerBackground: "rgba(205, 99, 255, 0.1)",
  footerLinksColor: "rgba(205, 99, 255, 0.2)",
  hrColor: "rgba(255, 255, 255, 0.5)",

  modalBackground: "#200942",
  totalPages: "#94A3B8",
};
