import { ApplicationRoutes, PageMeta } from "./types";

export const DEFAULT_META: PageMeta = {
	title: "Gamesfi",
	description: "React-redux boilerplate",
	image: "",
};

export const customMeta: { [key: string]: PageMeta } = {
	[ApplicationRoutes.HOME]: {
		title: "Gamesfi",
	},
	[ApplicationRoutes.GAMES]: {
		title: "Games",
	},
	[ApplicationRoutes.GAMEPLAY]: {
		title: "Gamesfi",
	},
	[ApplicationRoutes.GAMECATEGORY]: {
		title: "Gamesfi",
	},
	[ApplicationRoutes.GAMEDETAILS]: {
		title: "Gamesfi",
	},
	[ApplicationRoutes.FORGOTPASSWORD]: {
		title: "Forgot Password",
	},
	[ApplicationRoutes.WITHDRAW]: {
		title: "Gamesfi",
	},
	[ApplicationRoutes.REFERRAL]: {
		title: "Gamesfi",
	},
	[ApplicationRoutes.CONTACT]: {
		title: "Gamesfi",
	},
};
