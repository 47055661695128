//TODO : Will delete.

import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const ArrowDown: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M6 9L12 15L18 9" stroke="white" strokeLinecap="round" strokeLinejoin="round" fill="none" color='
            #fff'/>
        </Svg>
    )
}
export default ArrowDown
