import { FormButton, FormHeading, HideShowIcon, Input, PasswordShowHide } from "../styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { Modal } from "widgets/Modal";
import { FormLogoHeader } from "./FormHeader";
import React, { useState } from "react";
import Spinner from "components/Spinner";
import { useUserManager } from 'state/user/hooks';
import { resetPasswordUser } from "../../../../../state/user/post/resetPasswordUser";
import { ErrorData, IUser } from "state/user/types";
import { AxiosError } from "axios";
import { EyeIconHidden, EyeIconShow } from "components/Svg";
import { Flex } from "components/Box";
import { FormErrorMessage, RealTimeMessage } from "components/Footer/styles";
import { PASSWORD_VALIDATION, PASSWORD_MESSAGE } from "config/constants/endpoints";
interface Inputs {
  email: string
  password: string;
  confirm_password: string;
  code: string;
}
interface Props {
  email: string;
  code: string;
  handleClose: () => void;
}

export const ResetPasswordModal: React.FC<Props> = (props) => {
  const passwordValidation = PASSWORD_VALIDATION;
  const { handleClose, email, code } = props;
  const [loader, setLoader] = useState(false);
  const [, setUser] = useUserManager();

  const [errorMessage, setErrorMessage] = useState("")
  const [validUser, setValidUser] = useState(false)

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);
  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!passwordConfirmShown);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<Inputs>({
    mode: "onChange" || "onSubmit"
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoader(true);
      const singleuser: IUser = await resetPasswordUser(
        email,
        data.password,
        code
      );
      if (singleuser) {
        setLoader(false);
        setUser(singleuser)
        handleClose();
      }
    } catch (error) {
      const errormessage = (error as AxiosError).response.data;
      setErrorMessage((errormessage as ErrorData).message);
      setValidUser(true);
      setLoader(false);
    }
  };

  return (
    <Modal
      onDismiss={() => {
        handleClose();
      }}
      title={""}
      minWidth={"500px"}
    >
      <FormLogoHeader />

      <FormHeading>Create New Password</FormHeading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <RealTimeMessage>
          <PasswordShowHide>
            <Input
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="New Password"
              {...register("password", {
                required:
                  '*Password is Required.',
                pattern: {
                  value: passwordValidation,
                  message: PASSWORD_MESSAGE,
                }
              })}
              className={errors.password ? "invalid" : ""}
              style={{ marginBottom: "0rem" }}
              onFocus={() => setValidUser(false)}
            />
            <HideShowIcon onClick={togglePassword} className="hideIcon" type="button">
              {passwordShown ? <EyeIconHidden /> : <EyeIconShow />}
            </HideShowIcon>
          </PasswordShowHide>
          <Flex>
            {validUser && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            <RealTimeMessage />
          </Flex>
        </RealTimeMessage>

        <RealTimeMessage>
          <PasswordShowHide>
            <Input
              type={passwordConfirmShown ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm Password"
              {...register("confirm_password", {
                required:
                  '*Confirm Password is Required.',
                pattern: {
                  value: passwordValidation,
                  message: PASSWORD_MESSAGE
                },
                validate: {
                  checkEq: val => val === getValues('password') || "Confirm Password should be Same."
                }
              })}
              className={errors.confirm_password ? "invalid" : ""}
              style={{ marginBottom: "0rem" }}
              onFocus={() => setValidUser(false)}
            />
            <HideShowIcon onClick={toggleConfirmPassword} className="hideIcon" type="button">
              {passwordConfirmShown ? <EyeIconHidden /> : <EyeIconShow />}
            </HideShowIcon>
            <Flex>
              {validUser && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
              <FormErrorMessage>{errors.confirm_password?.message}</FormErrorMessage>
              <RealTimeMessage />
            </Flex>
          </PasswordShowHide>
        </RealTimeMessage>

        <FormButton type="submit" >{loader ? <Spinner classname="m-0" /> : 'Create Password'}</FormButton>
      </form>
    </Modal>
  );
};