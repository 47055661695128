import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
     <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16 17.332C18.2091 17.332 20 15.5412 20 13.332C20 11.1229 18.2091 9.33203 16 9.33203C13.7909 9.33203 12 11.1229 12 13.332C12 15.5412 13.7909 17.332 16 17.332Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.22461 25.1307C8.55462 24.0323 9.2299 23.0696 10.1503 22.3853C11.0706 21.7011 12.1871 21.3317 13.3339 21.332H18.6673C19.8156 21.3316 20.9334 21.7019 21.8545 22.3877C22.7755 23.0736 23.4506 24.0384 23.7793 25.1387" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</Svg>
  );
};

export default Icon;
