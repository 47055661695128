export const formatCash = (num) => {
  if (num < 1e3) return num;
  if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(1) + "K";
  if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + "M";
  if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(1) + "B";
  if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T";
};

export const mask = (mail) => {
  let split = mail.split('@')
  return mail.substr(0, 2) + new Array(split[0].length - 1).fill('.').join('') + "@" + split[1]
}