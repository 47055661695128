import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      {...props}
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.9375 1H3.0625C1.9252 1 1 1.9252 1 3.0625V15.4375C1 16.5748 1.9252 17.5 3.0625 17.5H9.25V42.9375C9.25 44.0748 10.1752 45 11.3125 45H34.6875C35.8248 45 36.75 44.0748 36.75 42.9375V37.4375V32.625V17.5H42.9375C44.0748 17.5 45 16.5748 45 15.4375V3.0625C45 1.9252 44.0748 1 42.9375 1ZM11.3125 43.625C10.2235 43.625 10.7599 42.0163 10.625 40.7653C11.2031 40.9438 34.886 40.9163 35.375 40.7653V42.9375C35.375 44.2621 34.3219 43.3299 11.3125 43.625ZM35.375 39.0874C35.375 39.2821 35.0809 39.5 34.6875 39.5C11.9252 39.2939 10.625 39.8961 10.625 39.0874V36.6234C11.3065 36.8661 10.0479 36.6986 34.6875 36.75C34.9299 36.75 35.1588 36.7003 35.375 36.6234V39.0874ZM35.375 32.625C35.2458 33.7758 35.7701 35.375 34.6875 35.375C11.6975 35.0803 10.625 36.0132 10.625 34.6875V6.5H35.375V32.625ZM9.25 12H7.1875V6.5H9.25V12ZM42.9375 16.125H36.75V13.375H40.1875V5.125H35.375H5.8125V13.375H9.25V16.125C4.02895 15.8907 2.375 16.6537 2.375 15.4375V3.0625C2.375 2.6835 2.6835 2.375 3.0625 2.375H42.9375C43.3165 2.375 43.625 2.6835 43.625 3.0625C43.3548 14.4999 44.244 16.125 42.9375 16.125ZM36.75 12V6.5H38.8125V12H36.75Z"
        fill="#CD63FF"
        stroke="#CD63FF"
      />
      <path
        d="M23.6877 25.5566V15.4375H22.3127V25.5566L19.3045 23.1504L18.4458 24.2239L23.0001 27.8675L27.5545 24.2239L26.6958 23.1504L23.6877 25.5566Z"
        fill="#CD63FF"
        stroke="#CD63FF"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
