import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const WishList: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="180" height="144" viewBox="0 0 180 144" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_2702_10039)">
                <path d="M89.6241 142.557C112.06 142.557 130.248 124.369 130.248 101.933C130.248 79.4966 112.06 61.3086 89.6241 61.3086C67.188 61.3086 49 79.4966 49 101.933C49 124.369 67.188 142.557 89.6241 142.557Z" fill="url(#paint0_linear_2702_10039)" />
                <path d="M107.409 85.0133C107.921 85.4323 100.333 95.6426 90.4637 107.808L84.474 115.039L83.6516 116.032L82.7826 115.117C75.024 107.172 69.7947 101.275 70.1671 100.903C70.5395 100.531 76.4516 105.791 84.3964 113.503L82.705 113.596L88.5706 106.241C98.4551 94.1064 106.881 84.5944 107.409 85.0133Z" fill="#9B5500" />
                <path d="M145.102 85.5771C143.907 81.3099 138.352 81.7133 138.352 81.7133L138.507 84.6926C141.719 84.134 143.705 87.8892 143.705 87.8892L145.102 85.5771Z" fill="url(#paint1_linear_2702_10039)" />
                <path d="M27.4336 94.5158C26.2388 90.2486 20.6836 90.6675 20.6836 90.6675L20.8388 93.6313C24.0508 93.0882 26.037 96.8434 26.037 96.8434L27.4336 94.5158Z" fill="#E0E0E0" />
                <path d="M92.2491 35.4876C98.6888 37.4427 102.692 30.0255 102.692 30.0255L98.7664 27.791C96.8577 32.3376 90.4336 31.81 90.4336 31.81L92.2491 35.4876Z" fill="#E0E0E0" />
                <path d="M56.5431 43.4492C53.5483 43.7596 53.1914 47.5303 53.1914 47.5303L55.1931 47.763C55.1931 45.5596 57.9397 44.6596 57.9397 44.6596L56.5431 43.4492Z" fill="url(#paint2_linear_2702_10039)" />
                <path d="M180.001 70.9607C180.435 67.9814 176.897 66.709 176.897 66.709L176.168 68.5866C178.309 69.1297 178.496 72.0159 178.496 72.0159L180.001 70.9607Z" fill="#E0E0E0" />
                <path d="M74.0162 4.25172C74.4818 1.27241 70.8973 0 70.8973 0L70.168 1.87759C72.3093 2.42069 72.5111 5.3069 72.5111 5.3069L74.0162 4.25172Z" fill="#E8505B" />
                <path d="M-0.00140644 52.3243C-0.54451 56.9795 5.64687 58.1278 5.64687 58.1278L6.76411 55.0243C3.05549 54.6829 2.51239 50.3691 2.51239 50.3691L-0.00140644 52.3243Z" fill="url(#paint3_linear_2702_10039)" />
                <path d="M137.761 35.3957C140.771 35.5664 141.734 31.9043 141.734 31.9043L139.794 31.3457C139.437 33.5336 136.551 33.9836 136.551 33.9836L137.761 35.3957Z" fill="url(#paint4_linear_2702_10039)" />
                <path d="M154.196 47.6526C153.389 47.8788 152.548 47.9577 151.713 47.8853C150.878 47.9594 150.037 47.8805 149.23 47.6526C150.869 47.3215 152.557 47.3215 154.196 47.6526Z" fill="url(#paint5_linear_2702_10039)" />
                <path d="M156.818 40.8887C157.064 41.7077 157.148 42.5668 157.066 43.418C157.147 44.2641 157.063 45.1179 156.818 45.9318C156.591 45.1143 156.513 44.2631 156.585 43.418C156.512 42.5677 156.591 41.7113 156.818 40.8887Z" fill="url(#paint6_linear_2702_10039)" />
                <path d="M159.625 47.4824C160.432 47.2561 161.273 47.1773 162.108 47.2496C162.943 47.1755 163.784 47.2544 164.591 47.4824C162.952 47.8134 161.264 47.8134 159.625 47.4824Z" fill="url(#paint7_linear_2702_10039)" />
                <path d="M156.986 54.3108C156.758 53.4936 156.68 52.6421 156.753 51.797C156.68 50.9519 156.758 50.1004 156.986 49.2832C157.233 50.0968 157.317 50.9509 157.234 51.797C157.317 52.6431 157.233 53.4972 156.986 54.3108Z" fill="url(#paint8_linear_2702_10039)" />
                <path d="M161.009 51.3459C160.9 51.3459 160.435 51.02 159.938 50.4459C159.441 49.8717 159.147 49.3131 159.24 49.22C159.333 49.1269 159.829 49.5459 160.31 50.1355C160.791 50.7252 161.117 51.2062 161.009 51.3459Z" fill="url(#paint9_linear_2702_10039)" />
                <path d="M160.773 43.6813C160.866 43.7744 160.54 44.271 160.028 44.783C159.516 45.2951 159.035 45.621 158.942 45.5279C158.849 45.4348 159.174 44.9537 159.686 44.4417C160.199 43.9296 160.68 43.5882 160.773 43.6813Z" fill="url(#paint10_linear_2702_10039)" />
                <path d="M155.078 45.6365C155.078 45.7451 154.535 45.4969 154.054 45.0934C153.573 44.69 153.247 44.2865 153.34 44.1779C153.433 44.0693 153.883 44.3176 154.364 44.721C154.845 45.1245 155.171 45.5279 155.078 45.6365Z" fill="url(#paint11_linear_2702_10039)" />
                <path d="M154.862 49.0032C154.955 49.0963 154.676 49.5773 154.21 50.0894C153.745 50.6015 153.295 50.9273 153.201 50.8342C153.108 50.7411 153.388 50.2601 153.853 49.7635C154.319 49.267 154.769 48.9256 154.862 49.0032Z" fill="url(#paint12_linear_2702_10039)" />
                <path d="M19.0085 33.8115C18.2052 34.0565 17.3616 34.1409 16.5257 34.0598C15.6899 34.1409 14.8462 34.0565 14.043 33.8115C14.8498 33.5853 15.6909 33.5064 16.5257 33.5788C17.3606 33.5064 18.2016 33.5853 19.0085 33.8115Z" fill="url(#paint13_linear_2702_10039)" />
                <path d="M21.724 27.0625C21.9593 27.8783 22.0383 28.7312 21.9567 29.5763C22.0388 30.4265 21.9598 31.2846 21.724 32.1056C21.478 31.2866 21.3937 30.4275 21.4757 29.5763C21.3945 28.7302 21.4789 27.8764 21.724 27.0625Z" fill="url(#paint14_linear_2702_10039)" />
                <path d="M24.457 33.656C25.26 33.4094 26.1039 33.325 26.9398 33.4078C27.7757 33.3266 28.6193 33.411 29.4225 33.656C28.6157 33.8823 27.7746 33.9611 26.9398 33.8888C26.1049 33.9629 25.2636 33.884 24.457 33.656Z" fill="url(#paint15_linear_2702_10039)" />
                <path d="M21.8177 40.3439C21.5726 39.53 21.4883 38.6762 21.5695 37.8301C21.4874 36.9789 21.5718 36.1198 21.8177 35.3008C22.0637 36.1198 22.148 36.9789 22.066 37.8301C22.1472 38.6762 22.0629 39.53 21.8177 40.3439Z" fill="url(#paint16_linear_2702_10039)" />
                <path d="M25.8213 37.5061C25.7282 37.5992 25.2471 37.1958 24.7661 36.6061C24.2851 36.0165 23.9592 35.4889 24.0678 35.3958C24.1764 35.3027 24.642 35.7217 25.1385 36.2958C25.6351 36.8699 25.9299 37.4285 25.8213 37.5061Z" fill="url(#paint17_linear_2702_10039)" />
                <path d="M25.6044 29.8557C25.6975 29.9488 25.3561 30.4298 24.8596 30.9419C24.363 31.454 23.8665 31.7954 23.7578 31.7022C23.6492 31.6091 24.0061 31.1126 24.5027 30.6005C24.9992 30.0885 25.4958 29.7626 25.6044 29.8557Z" fill="url(#paint18_linear_2702_10039)" />
                <path d="M19.9094 31.8103C19.8163 31.9035 19.3663 31.6707 18.8853 31.2672C18.4042 30.8638 18.0784 30.4603 18.156 30.3517C18.2335 30.2431 18.7146 30.4914 19.1956 30.8948C19.6766 31.2983 19.987 31.7017 19.9094 31.8103Z" fill="url(#paint19_linear_2702_10039)" />
                <path d="M19.6743 35.178C19.7829 35.2711 19.4881 35.7521 19.0226 36.2487C18.5571 36.7452 18.1226 37.0866 18.014 37.009C17.9053 36.9314 18.2157 36.4349 18.6657 35.9228C19.1157 35.4107 19.5812 35.0849 19.6743 35.178Z" fill="url(#paint20_linear_2702_10039)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2702_10039" x1="73.6963" y1="65.3234" x2="95.1462" y2="134.212" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint1_linear_2702_10039" x1="140.403" y1="82.0088" x2="141.921" y2="87.3291" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint2_linear_2702_10039" x1="54.6347" y1="43.6624" x2="55.6856" y2="47.3773" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint3_linear_2702_10039" x1="2.03155" y1="50.7525" x2="4.30809" y2="57.1598" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint4_linear_2702_10039" x1="138.126" y1="31.5461" x2="138.994" y2="35.1069" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint5_linear_2702_10039" x1="150.74" y1="47.4294" x2="150.755" y2="47.9014" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint6_linear_2702_10039" x1="156.722" y1="41.1379" x2="158.16" y2="41.6302" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint7_linear_2702_10039" x1="161.134" y1="47.2478" x2="161.149" y2="47.7198" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint8_linear_2702_10039" x1="156.89" y1="49.5316" x2="158.329" y2="50.0266" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint9_linear_2702_10039" x1="159.772" y1="49.3127" x2="160.417" y2="51.0641" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint10_linear_2702_10039" x1="159.492" y1="43.7588" x2="159.991" y2="45.3486" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint11_linear_2702_10039" x1="153.862" y1="44.2269" x2="154.211" y2="45.5386" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint12_linear_2702_10039" x1="153.699" y1="49.084" x2="154.227" y2="50.6319" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint13_linear_2702_10039" x1="15.5523" y1="33.5793" x2="15.5691" y2="34.0781" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint14_linear_2702_10039" x1="21.6103" y1="27.3117" x2="23.0587" y2="27.8124" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint15_linear_2702_10039" x1="25.9664" y1="33.4036" x2="25.9833" y2="33.9041" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint16_linear_2702_10039" x1="21.7085" y1="35.55" x2="23.187" y2="36.0748" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint17_linear_2702_10039" x1="24.593" y1="35.4884" x2="25.2401" y2="37.2361" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint18_linear_2702_10039" x1="24.3102" y1="29.9326" x2="24.8051" y2="31.5252" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint19_linear_2702_10039" x1="18.6846" y1="30.4005" x2="19.0296" y2="31.7089" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <linearGradient id="paint20_linear_2702_10039" x1="18.5103" y1="35.2539" x2="19.0362" y2="36.8034" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FBCF0A" />
                    <stop offset="1" stopColor="#FBA505" />
                </linearGradient>
                <clipPath id="clip0_2702_10039">
                    <rect width="180" height="144" fill="white" />
                </clipPath>
            </defs>

        </Svg>
    )
}
export default WishList
