import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      {...props}
      width="37"
      height="24"
      viewBox="0 0 37 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6L28 12L22 18"
        stroke="#CD63FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <g opacity="0.7">
        <path
          d="M14.125 6.75L18.875 11.5L14.125 16.25"
          stroke="#CD63FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <g opacity="0.5">
        <path
          d="M6 8L10 12L6 16"
          stroke="#CD63FF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
    </Svg>
  );
};

export default Icon;
