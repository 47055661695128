import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const DiscordIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.5 15.1667C11.1444 15.1667 11.6667 14.6443 11.6667 14C11.6667 13.3557 11.1444 12.8333 10.5 12.8333C9.85571 12.8333 9.33337 13.3557 9.33337 14C9.33337 14.6443 9.85571 15.1667 10.5 15.1667Z" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M17.5 15.1667C18.1444 15.1667 18.6667 14.6443 18.6667 14C18.6667 13.3557 18.1444 12.8333 17.5 12.8333C16.8557 12.8333 16.3334 13.3557 16.3334 14C16.3334 14.6443 16.8557 15.1667 17.5 15.1667Z" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M8.75 8.75C12.8333 7.58333 15.1667 7.58333 19.25 8.75" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M8.16663 19.25C12.25 20.4167 15.75 20.4167 19.8333 19.25" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M18.0834 19.8333C18.0834 21 19.8334 23.3333 20.4167 23.3333C22.1667 23.3333 23.7219 21.3885 24.5 19.8333C25.2782 17.8885 25.0834 13.0282 22.75 6.41666C21.0502 5.23249 19.25 4.85332 17.5 4.66666L16.3334 7.58332" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M9.91667 19.8333C9.91667 21 8.33467 23.3333 7.77934 23.3333C6.11217 23.3333 4.63167 21.3885 3.89084 19.8333C3.15001 17.8885 3.33551 13.0282 5.55684 6.41666C7.17617 5.23249 8.80251 4.85332 10.5 4.66666L11.6667 7.58332" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
        </Svg>
    )
}
export default DiscordIcon
