import {
    CenterDiv,
    ModalButton,
    FormCheckBox,
    FormHeading,
    FormSubHeading,
    Input,
    // SocialBody,
    FormButton,
    PasswordShowHide,
    HideShowIcon,
} from "../styles";

// import FbLogo from "assets/images/form/facebook.svg"
// import GoogleLogo from "assets/images/form/google.svg"
import { useForm, SubmitHandler } from "react-hook-form";
import { Modal, useModal } from "widgets/Modal";
import { FormLogoHeader } from "./FormHeader";
import { ForgotModal } from "./ForgotPassword";
import { SignupModal } from "./SignupModal";
// import { CheckBox } from "components/CheckBox";
import { loginUser } from '../../../../../state/user/post/loginUser';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { GoogleLogin } from 'react-google-login';
import { useUserManager } from 'state/user/hooks';
import { ErrorData, IUser } from "state/user/types";
import { useState } from "react";
import Spinner from "components/Spinner"
// import { Box } from "components/Box";
import { AxiosError } from "axios";
import { EyeIconHidden, EyeIconShow } from "components/Svg";
import { Flex } from "components/Box";
import { FormErrorMessage, RealTimeMessage } from "components/Footer/styles";
import { EMAIL_VALIDATION } from "config/constants/endpoints";
interface Inputs {
    email: string;
    password: string;
}

export const LoginModal = (props) => {
    const [showForgotModal, onForgotDismiss] = useModal(
        <ForgotModal handleClose={() => onForgotDismiss()} />,
        true
    );
    const [showSignupModal, onSignupDismiss] = useModal(
        <SignupModal handleClose={() => onSignupDismiss()} />,
        true
    );

    const emailValidation = EMAIL_VALIDATION;
    const { handleClose } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [user, setUser] = useUserManager();
    const [validUser, setValidUser] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loader, setLoader] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<Inputs>(
        {
            mode: "onChange" || "onSubmit"
        }
    );
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            setLoader(true)
            const singleuser: IUser = await loginUser(data?.email, data?.password);
            if (singleuser) {
                setLoader(false)
                setUser(singleuser);
                handleClose()
                setValidUser(false)
                reset();
            }
        } catch (error) {
            const errormessage = (error as AxiosError).response.data;
            setErrorMessage((errormessage as ErrorData).message)
            setValidUser(true);
            setLoader(false)
        }
    }

    // const responseFacebook = (response) => {
    // }

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <Modal
            onDismiss={() => {
                handleClose();
            }}
            title={""}
            minWidth={"500px"}
        >
            <FormLogoHeader />

            <FormHeading>Login to your account</FormHeading>

            <form onSubmit={handleSubmit(onSubmit)}>
                <RealTimeMessage>
                    <Input
                        type="text"
                        name="email"
                        placeholder="Email"
                        {...register("email", {
                            required: '*Email is Required.',
                            pattern: {
                                value: emailValidation,
                                message: "Invalid Email Address"
                            },
                        })}
                        className={errors.email ? "invalid" : ""}
                        style={{ marginBottom: "0rem" }}
                        onFocus={() => setValidUser(false)}
                        onBlur={(e: any) => setValue("email", e.target.value.trim())}
                    />
                    <Flex>
                        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                        <RealTimeMessage />
                    </Flex>
                </RealTimeMessage>

                <RealTimeMessage>
                    <PasswordShowHide>
                        <Input
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            {...register("password", {
                                required: '*Password is Required.'
                            })}
                            className={errors.password ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                            onBlur={(e: any) => setValue("password", e.target.value.trim())}
                            onFocus={() => setValidUser(false)}
                        />
                        <HideShowIcon onClick={togglePassword} className="hideIcon" type="button">
                            {passwordShown ? <EyeIconHidden /> : <EyeIconShow />}
                        </HideShowIcon>
                    </PasswordShowHide>

                    <Flex>
                        {validUser && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
                        <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
                        <RealTimeMessage />
                    </Flex>
                </RealTimeMessage>

                <FormCheckBox>
                    {/* <Box className="d-flex">

                        <CheckBox
                            label=""
                            type="checkbox"
                            // checked={true} //here bind value
                            onChange={() => {
                            }}
                        />

                        <FormSubHeading style={{ marginLeft: '1rem' }}>Remember me</FormSubHeading>
                    </Box> */}
                    <ModalButton type="button" onClick={(e) => showForgotModal()}>Forgot Password?</ModalButton>
                </FormCheckBox>

                <FormButton type="submit" >{loader ? <Spinner classname="m-0" /> : 'Login'}</FormButton>
            </form>
            {/* <CenterDiv>
                <FormSubHeading>Or login with</FormSubHeading>
            </CenterDiv>

            <SocialBody>
                <GoogleLogin
                    clientId="808346269769-qj7p1etrq333s6ilc4m1f6vcvja31e2j.apps.googleusercontent.com"
                    render={(renderProps) => (
                        <button
                            onClick={renderProps.onClick}
                            className="fb-btn "
                            disabled={renderProps.disabled}
                        >
                            {" "}
                            <img src={GoogleLogo} alt="" /> Google
                        </button>
                    )}
                    buttonText="Login"
                    onSuccess={responseFacebook}
                    cookiePolicy={"single_host_origin"}
                    className="connect-fb active"
                    fetchBasicProfile={true}
                />
                <FacebookLogin
                    appId="5374180382645493"
                    autoLoad={false}
                    callback={responseFacebook}
                    render={renderProps => (
                        <button className="fb-btn" onClick={renderProps.onClick}>
                            <img src={FbLogo} alt="" />
                            Facebook
                        </button>
                    )}
                />
            </SocialBody> */}

            <CenterDiv>
                <FormSubHeading>
                    Don’t have an account?
                    <ModalButton onClick={() => showSignupModal()}>Signup</ModalButton>
                </FormSubHeading>
            </CenterDiv>
        </Modal>
    );
};
