import { LogoDiv } from "../styles";
import { ReactComponent as FormLogo } from "assets/images/form/formLogo.svg";

export const FormLogoHeader = () => {
    return (
        <LogoDiv>
            <FormLogo />
        </LogoDiv>

    );
};