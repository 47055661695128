import { WishList } from "components/Svg";
import { Text } from "components/Text";
import { Modal } from "widgets/Modal";
import useCurrentTime from "hooks/useTimer";
import moment from 'moment';
import { WishListBody } from "../../withdraw/components/ModalStyle";

export const ChangePasswordWishlist = (props) => {
    const { handleClose } = props;
    const currentTime = useCurrentTime();

    return (
        <Modal
            onDismiss={() => {
                handleClose();
            }}
            title={""}
            minWidth={"500px"}
        >
            <WishListBody>
                <WishList />

                <Text
                    fontSize="2rem"
                    fontWeight="500"
                >Your Password has been updated successfully. Please login again.</Text>

                <Text fontSize="1.2rem" fontWeight="400">
                    {moment(currentTime).format('DD-MM-YYYY h:mm a')}
                </Text>

            </WishListBody>
        </Modal>

    );
};
