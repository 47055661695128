import { Container, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Box } from "components/Box";
import { Text } from "components/Text";
import Offcanvas from 'react-bootstrap/Offcanvas';
import settingBtn from 'assets/images/settings.svg'
import Close from 'assets/images/Cross.svg'
import styled from "styled-components";
import Wrapper from "components/Wrapper";

export const HeaderNavBar = styled(Navbar)`
  background-color: ${(props) =>
    props.theme.colors.headerBackground} !important;
  height: 12rem;
  width:100%;
  .fillTransparent{
    fill:transparent;
  }
  .NavlogoWidth{
    max-width: 50%;
  }
  .navbar-toggler{
    padding: 0;
&.nav-body-toggle{
      position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        cursor: initial;
        span {
          display: none;
        }
        &.collapsed {
          z-index: -1;
          pointer-events: none;
        }
  }}
  @media (max-width: 992px) {
    height: 12rem;
  }
`;
export const OffcanvasCloseIcon = styled(Offcanvas.Header)`
  background: url(${Close}) !important;
  height: 3rem;
  width: 3rem;
`
export const NavBarContainer = styled(Container)`
  width: 95% !important;
  max-width: 1900px !important;
  display: flex;
`;
export const NavBarCollapse = styled(Navbar.Collapse)`
    z-index: 5;
  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0rem;
  }
  @media (max-width: 991px) {
    background-color: #200942 !important;
    padding: 2rem 0rem;
  }
`;
export const RouterNavLink = styled(NavLink)`
  font-family: Avenir Roman;
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 1.8rem;
  font-weight: 400 !important;
  text-decoration: none;
  border: none;
  margin: 0rem 2rem;
  &:hover {
    color: ${(props) => props.theme.colors.textTertiary};
  }
  &:active {
    background: none !important;
    color: ${(props) => props.theme.colors.textTertiary};
  }
  @media (max-width: 991px) {
    margin: 0.5rem 0rem;
  }
  @media (max-width: 1270px) {
    font-size: 1.6rem;
  }
`;
export const RouterATag = styled.a`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: 1.8rem;
  font-weight: 400 !important;
  text-decoration: none;
  border: none;
  margin: 0rem 2rem;
  &:hover {
  }
  &:active {
    background: none !important;
  }
  @media (max-width: 991px) {
    margin: 0.5rem 0rem;
  }
`
export const LogoutIcon = styled(Dropdown)`
  cursor: pointer;
  .btn-primary {
    background-image: url(${settingBtn});
    border: none !important;
    box-shadow: none !important;
    background-size: contain;
    height: 5rem;
    background-color: transparent;
    background-repeat: no-repeat;
    @media (max-width: 991px) {
      background-image: none;
    }
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .dropdown-toggle{
    background-color: transparent !important;
  }
  .dropdown-item:active{
    background-color: transparent !important;
  }

  .dropdown-menu {
    background: linear-gradient(90deg, rgba(201, 87, 255, 0.9) -1645%, rgba(129, 0, 190, 0.9) 5551%);
    width: 270px;
    ${(props) => props.theme.mediaQueries.sm} {
      width: 240px;
    }
  }
  .dropdown-item {
    color: white;
    svg{
      stroke: white;
    }
  }
  .dropdown-item:hover {
    background-color: white;
    color: #cd63ff;

    svg{
      stroke: #cd63ff;
      /* fill: purple; */
    }
  }
  .showtrue {
    opacity: 1;
  }
  .showfalse {
    opacity: 0;
  }
  span{
    margin-left: 1rem;
    font-weight: 500;
    font-size: 16px;
  }
  hr{
    border: 1px solid ${(props) => props.theme.colors.hrColor} !important;
    background-color: transparent;
    opacity: unset;
  }
  .logoutButton{
    @media only screen and (max-width: 1440px) and (min-width: 400px) {
      height: 5rem;
      margin: 0 0 0 1rem;
    }
   @media only screen and (max-width: 2100px) and (min-width: 1500px){
      height: 4rem;
      margin: 0 0 0 1rem;
    }
`;

export const HeaderWrapper = styled(Wrapper)`
 @media (max-width: 992px) {
  margin-top: 0;
  padding-top: 12rem !important;
  background-color: ${(props) => props.theme.colors.headerBackground};
 }
`
export const OffCanvasWrapper = styled(Box)`
  padding: 3rem 1.5rem;
  ${Text}{
    color: ${(props) => props.theme.colors.textPrimary};
    margin-left: 1rem;
  }
  img{
    width: 8rem;
    height: 8rem;
  }
  hr{
    border: 1px solid rgba(205, 99, 255, 0.2);
  }
  .btn-disable{
    cursor: auto;
  }
`
export const ProfileMenu = styled(Box)`
  padding: 1rem;
  cursor: auto;
  span{
    color: ${(props) => props.theme.colors.textPrimary};
    font-size: 1.2rem;
  }
`