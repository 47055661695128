import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const Location: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.0001 15.6683C16.0104 15.6683 17.6401 14.0386 17.6401 12.0283C17.6401 10.018 16.0104 8.38831 14.0001 8.38831C11.9898 8.38831 10.3601 10.018 10.3601 12.0283C10.3601 14.0386 11.9898 15.6683 14.0001 15.6683Z" stroke="#CD63FF" strokeWidth="1.5" fill='none' />
            <path d="M4.2234 9.90504C6.52173 -0.19829 21.4901 -0.186623 23.7767 9.91671C25.1184 15.8434 21.4317 20.86 18.2001 23.9634C15.8551 26.2267 12.1451 26.2267 9.7884 23.9634C6.5684 20.86 2.88173 15.8317 4.2234 9.90504Z" stroke="#CD63FF" strokeWidth="1.5" fill='none' />
        </Svg>
    )
}
export default Location
