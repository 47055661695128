import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ArrowRight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="8" height="14" viewBox="0 0 8 14" {...props}>
      <path d="M7 13L1 7L7 1" stroke="#CBD5E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    </Svg>
  );
};

export default ArrowRight;
