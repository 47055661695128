import React from 'react'
import {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalCloseButton,
  ModalBackButton,
  ModalBorder,
  BackIcon,
} from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = true,
  bodyPadding,
  headerBackground = 'transparent',
  minWidth = '320px',
  ...props
}) => {
  return (
    <ModalContainer className="modal-overflow modal-overflowY" minWidth={minWidth} {...props}>
      <ModalBorder padding={bodyPadding}>
        <ModalHeader background={headerBackground}>
          <ModalTitle padding={bodyPadding}>
            {onBack && (
              <ModalBackButton onBack={onBack}>
                <BackIcon />
              </ModalBackButton>
            )}
            {title}
          </ModalTitle>
          {!hideCloseButton && <ModalCloseButton onDismiss={onDismiss} />}
        </ModalHeader>
        <ModalBody p={bodyPadding}>{children}</ModalBody>
      </ModalBorder>
    </ModalContainer>
  )
}

export default Modal
