import React from 'react'
import useTheme from "hooks/useTheme";
import useViewport from "hooks/useViewport";
import { FooterLogoBody, FooterWrapper } from '../../styles';
import { Text } from 'components/Text';
import footerCopyRightLogo from "assets/images/footer-copyright-logo.svg";

const CopyRights: React.FC = () => {

    const { theme } = useTheme();
    const { width } = useViewport();

    return (
        <FooterWrapper sm={6}>
            <FooterLogoBody justifyContent='center'>
                <Text
                    fontFamily="Jost, sans-serif !important"
                    fontSize={width < 992 ? "2rem" : "1.7rem"}
                    fontWeight="500"
                    style={{
                        color: theme.colors.textPrimary,
                    }}
                >
                    Copyright © 2022
                </Text>
                <img
                    src={footerCopyRightLogo}
                    alt="copyRight-icon"
                    style={{ margin: "0rem 0rem 0rem 2rem" }}
                />
            </FooterLogoBody>
        </FooterWrapper>
    )
}
export default CopyRights
