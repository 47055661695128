import React from "react";
import Lottie from "react-lottie";
import animationData from "./loader.json";

type ReloaderProps = {
  className?: string;
  style?: React.CSSProperties;
};

export const Reloader = (props: ReloaderProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div style={props.style} className={props.className}>
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  );
};
