import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

export const ContainerRow = styled(Container)`
min-height: inherit;
  max-width: 1920px !important;
  width: 90% !important;
  /* border: 0.1rem solid red; */
  /* padding: 6rem 0rem 6rem 0rem; */
`;
export const RowCustom = styled(Row)`
`;
export const CurrencyRowCustom = styled(Row)`

width:100%;
display:flex;
justify-content:center;
flex-direction:row;
flex-wrap: nowrap;
overflow-x:scroll;
&::-webkit-scrollbar {
  border: none;
}
`;
export const SelectRowCustom = styled(Row)`

width:100%;
max-width:1200px;
display:flex;
justify-content:center;
flex-direction:row;
flex-wrap: nowrap;
overflow-x:scroll;
&::-webkit-scrollbar {
  border: none;
}
@media screen and (max-width:1300px){
  justify-content: initial;
}
`;
export const ColCustom = styled(Col)``;
