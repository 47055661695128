import { FormButton, FormForgotText, FormHeading, Input } from "../styles";
import { useForm, SubmitHandler } from "react-hook-form";
import { Modal } from "widgets/Modal";
import { FormLogoHeader } from "./FormHeader";
// import { CheckForgotEmailModal } from "./CheckForgotEmail";
import { forgotPasswordUser } from '../../../../../state/user/post/forgotPasswordUser';
import { useEffect, useState } from "react";
import Spinner from "components/Spinner"
// import { CheckForgotEmailModal } from "./CheckForgotEmail";
import { FormErrorMessage, RealTimeMessage } from "components/Footer/styles";
import { Flex } from "components/Box";
import { ErrorData, IUser } from "state/user/types";
import { AxiosError } from "axios";
import { EMAIL_VALIDATION } from "config/constants/endpoints";

interface Inputs {
    email: string;
}

export const ForgotModal = (props) => {
    // const [showCheckForgotModal, onLoginDismiss] = useModal(
    //     <CheckForgotEmailModal handleClose={() => onLoginDismiss()} />,
    //     true
    // );
    const emailValidation = EMAIL_VALIDATION;
    const { handleClose } = props;
    const [loader, setLoader] = useState(false);
    const [validEmail, setValidEmail] = useState(false)
    const [counter, setCounter] = useState(60);
    const [sendAgainTimer, setSendAgainTimer] = useState(false)
    const [showInput, setShowInput] = useState(true)
    const [userEmail, setUserEmail] = useState("")
    const [resError, setResError] = useState("")

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>(
        {
            mode: "onChange" || "onSubmit"
        }
    );

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            setUserEmail(data?.email)
            const uservalid: IUser = await forgotPasswordUser(data?.email);
            if (uservalid) {
                setSendAgainTimer(true)
                setLoader(false)
                setShowInput(false)
                setValidEmail(false)
            }

        } catch (error) {
            const errormessage = (error as AxiosError).response.data;
            setResError((errormessage as ErrorData).message)
            setLoader(false)
            setValidEmail(true)
        }
    }

    const reSendMail = async (data) => {
        setLoader(true)
        try {
            const singleuser = await forgotPasswordUser(userEmail);
            if (singleuser) {
                setSendAgainTimer(true)
                setLoader(false)
                setValidEmail(false)
            }
        } catch (error) {
            const errormessage = (error as AxiosError).response.data;
            setResError((errormessage as ErrorData).message)
            setLoader(false)
        }

    }
    useEffect(() => {
        if (counter > 0 && sendAgainTimer) { setTimeout(() => setCounter(counter - 1), 1000); };
        if (counter === 0) {
            setSendAgainTimer(false)
            setCounter(60)
        }
    }, [counter, sendAgainTimer]);

    return (
        <Modal
            onDismiss={() => {
                handleClose();
            }}
            title={""}
            minWidth={"500px"}
        >
            <FormLogoHeader />

            <FormHeading>Forgot Password</FormHeading>
            {showInput ?
                <FormForgotText>
                    Please enter the email address associated with your account. If you are
                    facing any issues than please contact us at support@gamesfi.com
                </FormForgotText>
                :
                <FormForgotText>We have sent you the instructions in the mail. Please follow the instructions to recover your password.</FormForgotText>

            }
            {showInput
                &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <RealTimeMessage>
                        <Input
                            type="text"
                            placeholder="Email"
                            {...register("email", {
                                required: "*Email is Required.",
                                pattern: {
                                    value: emailValidation,
                                    message: "*Please enter a valid email address.",
                                }
                            })}
                            className={errors.email ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                            onFocus={() => setValidEmail(false)}

                        />
                        <Flex>
                            {validEmail && <FormErrorMessage>{resError} </FormErrorMessage>}
                            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
                        </Flex>
                    </RealTimeMessage>

                    <FormButton type="submit">
                        {loader ? <Spinner classname="m-0" /> : 'Recover Password'}
                    </FormButton>
                </form>
            }

            {!showInput &&
                <FormButton onClick={() => reSendMail(userEmail)} disabled={sendAgainTimer ? true : false}>
                    {sendAgainTimer ? `Resend in ${counter}` : loader ? <Spinner classname="m-0" /> : 'Resend Email'}
                </FormButton>
            }
        </Modal>
    );
};
