import { Text } from "components/Text";
import styled from "styled-components";
import { Flex } from "components/Box";

export const SelectTitle = styled(Text)`
    font-family: Avenir Roman;
    font-style: normal;
    font-weight: 900;
    font-size: 2.4rem;
    color: ${(props) => props.theme.colors.textPrimary};
    margin: 2rem 0;
`
export const CurrencyWrapper = styled(Flex)`
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`
export const GameWrapper = styled(Flex)`
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.7rem 0;
    ${Text}{
        color: ${(props) => props.theme.colors.textSecondary};
    }
`
export const ModalLoader = styled(Flex)`
    height: 350px;
    img{
        margin: auto;
    }
`
export const WishListBody = styled(Flex)`
    text-align: center;
    flex-direction: column;
    padding: 3rem 2rem;
    ${Text}{
        font-family: Avenir Roman;
        color: ${(props) => props.theme.colors.textPrimary};
        padding: 1rem 0;
    }
`