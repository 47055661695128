import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const GoBackIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_3125_10082)">
                <path d="M19 12H5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 18L5 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11 6L5 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3125_10082">
                    <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
                </clipPath>
            </defs>
        </Svg>
    )
}
export default GoBackIcon
