import { REACT_APP_APIS } from "config/constants/endpoints";
import axios from "state/axios/interceptors";
import qs from "qs";

export const getRandomGames = async (param) => {
	const url = `${REACT_APP_APIS}/games/random/${param}`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getAllGames = async () => {
	const url = `${REACT_APP_APIS}/games`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getGamesByCatagory = async (categoryId: string) => {
	const url = `${REACT_APP_APIS}/games?categoryId=${categoryId}`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getGameByID = async (id: string) => {
	const url = `${REACT_APP_APIS}/games?id=${id}`;
	const result = await axios({
		url,
		method: "Get",
	});
	const gamesData = result.data.games;
	const singleGame = gamesData.filter((game) => game.id === parseInt(id));
	return singleGame;
};

export const getUpcomingGames = async () => {
	const url = `${REACT_APP_APIS}/games?isActive[0]=false`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getUpcomingHomeGames = async () => {
	const url = `${REACT_APP_APIS}/up-coming/games`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getCurruncy = async (param) => {
	const url = `${REACT_APP_APIS}/currencies/list?gameId=${param}`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getActiveUsers = async () => {
	const url = `${REACT_APP_APIS}/user/active/count`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getINXPaidOut = async () => {
	const url = `${REACT_APP_APIS}/user/inx/paid-out`;
	const result = await axios({
		url,
		method: "Get",
	});
	return result.data;
};

export const getUserScoreByID = async (gameId: number) => {
	const url = `${REACT_APP_APIS}/user/scores`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
		params: {
			gameId: gameId,
		},
	});
	return result.data;
};

export const getUserWithdrawHistory = async (active: number, LIMIT: number) => {
	const url = `${REACT_APP_APIS}/user/transactions`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
		params: {
			pageSize: LIMIT,
			pageNumber: active - 1,
		},
	});
	return result.data;
};

/**
 * Send Contact Us Form.
 */
export const contactUs = async (
	name,
	email,
	message
): Promise<{
	code: number;
	message: string;
}> => {
	try {
		const { data } = await axios({
			data: qs.stringify({
				name,
				email,
				message,
			}),
			url: `${REACT_APP_APIS}/user/contact-us`,
			headers: { "content-type": "application/x-www-form-urlencoded" },
			method: "POST",
		});
		return {
			...data,
		};
	} catch (error) {
		console.error("Failed to post user express interest", error);
		return null;
	}
};

export const getTotalReferral = async () => {
	const url = `${REACT_APP_APIS}/user/invites`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
	});
	return result.data;
};

export const getReferralNotifications = async (
	active: number,
	LIMIT: number
) => {
	const url = `${REACT_APP_APIS}/user/notifications`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
		params: {
			pageSize: LIMIT,
			pageNumber: active - 1,
		},
	});
	return result.data;
};

export const getUserTotalScore = async () => {
	const url = `${REACT_APP_APIS}/user/total/scores`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
	});
	return result.data.userScore.totalscore ?? 0;
};
export const getUserConvertedAmount = async (amountInINX, symbol) => {
	const url = `${REACT_APP_APIS}/user/inx-conversion`;
	const data = {
		amountInINX: amountInINX,
		symbol: symbol
	}
	const result = await axios({
		url,
		method: "Post",
		data: data,
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
	});
	return result.data ?? 0;
};

/**
 * LeaderBoard.
 */

export const getCurrentEvent = async () => {
	const url = `${REACT_APP_APIS}/user/event/current`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
	})
	return result.data;
}
export const getNextScheduledEvent = async () => {
	const url = `${REACT_APP_APIS}/user/event/scheduled`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
		params: { pageSize: 1, pageNumber: 0 }
	})
	return result.data;
}

export const getRecentEvents = async (allevents) => {
	const url = `${REACT_APP_APIS}/user/event/recent`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
		params: { pageSize: allevents ? 0 : 1, pageNumber: allevents ? -1 : 0 }
	})
	return result.data;
}

export const getRecentEvenDetails = async (id) => {
	const url = `${REACT_APP_APIS}/user/event/recent-details`;
	const result = await axios({
		url,
		method: "Get",
		headers: {
			jwt: localStorage.getItem("jwt"),
		},
		params: { eventId: id }
	})
	return result.data;
}