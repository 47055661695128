import {
    FormHeading,
    Input,
    FormButton,
    PasswordShowHide,
    HideShowIcon,
} from "../styles";

import { useForm, SubmitHandler } from "react-hook-form";
import { Modal, useModal } from "widgets/Modal";
import { FormLogoHeader } from "./FormHeader";
import { useUserManager } from 'state/user/hooks';
import { ErrorData } from "state/user/types";
import { useState } from "react";
import Spinner from "components/Spinner"
import { AxiosError } from "axios";
import { EyeIconHidden, EyeIconShow } from "components/Svg";
import { Flex } from "components/Box";
import { FormErrorMessage, RealTimeMessage } from "components/Footer/styles";
import { IUserPasswordChange, userChangePassword } from "state/user/post/changePassword";
import { ChangePasswordWishlist } from "./ChangePasswordWishlist";
import { PASSWORD_VALIDATION, PASSWORD_MESSAGE } from "config/constants/endpoints";

export const ChangePasswordModal = (props) => {
    const passwordValidation = PASSWORD_VALIDATION;
    const passwordMessage = PASSWORD_MESSAGE;
    const { handleClose } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [user, setUser] = useUserManager();
    const [validUser, setValidUser] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loader, setLoader] = useState(false);

    const [showChangePasswordWishlist, onShowChangePasswordWishlist] = useModal(
        <ChangePasswordWishlist handleClose={() => onShowChangePasswordWishlist()} />,
        true
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        getValues,
    } = useForm<IUserPasswordChange>(
        {
            mode: "onChange" || "onSubmit"
        }
    );
    const onSubmit: SubmitHandler<IUserPasswordChange> = async (data) => {
        try {
            setLoader(true)
            const singleuser: IUserPasswordChange = await userChangePassword(data?.oldPassword, data?.newPassword);
            if (singleuser) {
                setLoader(false)
                setUser(singleuser);
                showChangePasswordWishlist();
                setUser(null);
                localStorage.clear();
                setValidUser(false)
                reset();
            }
        } catch (error) {
            const errormessage = (error as AxiosError).response.data;
            setErrorMessage((errormessage as ErrorData).message)
            setValidUser(true);
            setLoader(false)
        }
    }

    // Current Password Icon State
    const [showCurrentPassword, setCurrentPassword] = useState(false);
    const toggleCurrentPassword = () => {
        setCurrentPassword(!showCurrentPassword);
    };
    // New Password Icon State
    const [showNewPassword, setNewPassword] = useState(false);
    const toggleNewPassword = () => {
        setNewPassword(!showNewPassword);
    };
    // Confirm Password Icon State
    const [showConfirmPassword, setConfirmPassword] = useState(false);
    const toggleConfirmPassword = () => {
        setConfirmPassword(!showConfirmPassword);
    };

    return (
        <Modal
            onDismiss={() => {
                handleClose();
            }}
            title={""}
            minWidth={"500px"}
        >
            <FormLogoHeader />

            <FormHeading>Change Password</FormHeading>

            <form onSubmit={handleSubmit(onSubmit)}>
                <RealTimeMessage>
                    <PasswordShowHide>
                        <Input
                            type={showCurrentPassword ? "text" : "password"}
                            name="password"
                            placeholder="Current Password"
                            {...register("oldPassword", {
                                required: '*Current Password is Required.',
                            })}
                            className={errors.oldPassword ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                            onBlur={(e: any) => setValue("oldPassword", e.target.value.trim())}
                            onFocus={() => setValidUser(false)}
                        />
                        <HideShowIcon onClick={toggleCurrentPassword} className="hideIcon" type="button">
                            {showCurrentPassword ? <EyeIconHidden /> : <EyeIconShow />}
                        </HideShowIcon>
                    </PasswordShowHide>

                    <Flex>
                        {/* {validUser && <FormErrorMessage>{errorMessage}</FormErrorMessage>} */}
                        <FormErrorMessage>{errors.oldPassword?.message}</FormErrorMessage>
                        <RealTimeMessage />
                    </Flex>
                </RealTimeMessage>

                <RealTimeMessage>
                    <PasswordShowHide>
                        <Input
                            type={showNewPassword ? "text" : "password"}
                            name="password"
                            placeholder="New Password"
                            {...register("newPassword", {
                                required: '*New Password is Required.',
                                pattern: {
                                    value: passwordValidation,
                                    message: passwordMessage
                                }
                            })}
                            className={errors.newPassword ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                            onBlur={(e: any) => setValue("newPassword", e.target.value.trim())}
                            onFocus={() => setValidUser(false)}
                        />
                        <HideShowIcon onClick={toggleNewPassword} className="hideIcon" type="button">
                            {showNewPassword ? <EyeIconHidden /> : <EyeIconShow />}
                        </HideShowIcon>
                    </PasswordShowHide>

                    <Flex>
                        {/* {validUser && <FormErrorMessage>{errorMessage}</FormErrorMessage>} */}
                        <FormErrorMessage>{errors.newPassword?.message}</FormErrorMessage>
                        <RealTimeMessage />
                    </Flex>
                </RealTimeMessage>

                <RealTimeMessage>
                    <PasswordShowHide>
                        <Input
                            type={showConfirmPassword ? "text" : "password"}
                            name="password"
                            placeholder="Confirm Password"
                            {...register("confirmPassword", {
                                required: '*Confirm Password is Required.',
                                pattern: {
                                    value: passwordValidation,
                                    message: passwordMessage
                                }, validate: {
                                    checkEq: val => val === getValues('newPassword') || "Password and confirm password should be the same."
                                }
                            })}
                            className={errors.confirmPassword ? "invalid" : ""}
                            style={{ marginBottom: "0rem" }}
                            onBlur={(e: any) => setValue("confirmPassword", e.target.value.trim())}
                            onFocus={() => setValidUser(false)}
                        />
                        <HideShowIcon onClick={toggleConfirmPassword} className="hideIcon" type="button">
                            {showConfirmPassword ? <EyeIconHidden /> : <EyeIconShow />}
                        </HideShowIcon>
                    </PasswordShowHide>

                    <Flex>
                        {validUser && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
                        <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>
                        <RealTimeMessage />
                    </Flex>
                </RealTimeMessage>

                <FormButton type="submit" >{loader ? <Spinner classname="m-0" /> : 'Save Changes'}</FormButton>
            </form>
        </Modal>
    );
};
