import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const GameRightArrow: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M13 18L19 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M13 6L19 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
        </Svg>
    )
}
export default GameRightArrow
