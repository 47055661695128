import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const MediumIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M13.7291 16.2099L14.3495 17.5704L12.8767 21.3112L7.18903 8.90427L5.846 9.19745V7.92856C5.88579 7.36418 5.67531 6.81287 5.27451 6.41856L4.09763 5H8.61791L13.7291 16.2099ZM5.27104 20.2093C5.38828 20.083 5.48909 19.945 5.5726 19.7984C5.65766 19.9456 5.7598 20.084 5.87804 20.2106L7.36066 22.009H3.78734L5.27104 20.2093ZM23.4167 21.5592L23.9196 22.053H18.5939L19.1785 21.4856L19.1838 21.4804L19.1891 21.4751C19.3012 21.363 19.4992 21.1588 19.6006 20.8425C19.6757 20.6081 19.6732 20.3622 19.6722 20.2576C19.6721 20.2473 19.672 20.2384 19.672 20.231V9.321L18.4076 9.08107L20.0435 5H23.9324L23.4224 5.48899C23.0261 5.82068 22.8202 6.32702 22.871 6.83978V20.214C22.8203 20.7245 23.0249 21.2281 23.4167 21.5592Z" stroke="#CD63FF" strokeWidth="2" fill='none' />
        </Svg>
    )
}
export default MediumIcon
