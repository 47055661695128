import React from 'react'
import { Row } from "react-bootstrap";
import useViewport from "hooks/useViewport";
import PrivacyPolicy from './components/PrivacyPolicy';
import CopyRights from './components/CopyRights';
import TermsOfServices from './components/TermsOfServices';
import { CustomRow, MblCustomRow, MblPolicySection } from '../styles';

const FooterBottom: React.FC = () => {
    const { width } = useViewport();

    return (
        <>
            <CustomRow>
                <hr />
            </CustomRow>

            {
                width > 992 ?
                    <Row>
                        <PrivacyPolicy />

                        <CopyRights />

                        <TermsOfServices />
                    </Row>
                    :
                    <Row display="none"></Row>
            }

            {
                width < 992 ?
                    <Row>
                        <MblPolicySection>
                            <PrivacyPolicy />
                            <TermsOfServices />
                        </MblPolicySection>

                        <MblCustomRow>
                            <CopyRights />
                        </MblCustomRow>
                    </Row>
                    :
                    <Row display="none"></Row>
            }
        </>
    )
}
export default FooterBottom
