import { Box } from "components/Box";
import { RowCustom } from "components/ContainerCustom";
import { Text } from "components/Text";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Flex } from "components/Box";
import { Col, Row } from "react-bootstrap";

export const FooterHeading = styled(Text)`
  font-family: Avenir Roman;
  font-size: 2rem;
  font-weight: 800;
  color: ${(props) => props.theme.colors.textPrimary};
`;
export const FooterSubHeading = styled(Text)`
  font-family: Avenir Roman;
  font-size: 1.8rem;
  font-weight: 400;
  color: ${(props) => props.theme.colors.textSecondary};
  :hover {
    color: ${(props) => props.theme.colors.textTertiary};
  }
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
`;
export const QuickLinks = styled.a`
  text-decoration: none;
  margin: 1.2rem 0rem;
`;
export const FooterLogoBody = styled(Flex)`
  width: 100%;
  align-items: center;
`;
export const FooterWrapper = styled(Col)`
  display: flex;
`;
export const FooterLogoText = styled(Text)`
  // font-size: 1.6rem;
  font-weight: 500;
  font-family: Avenir Roman;
  color: ${(props) => props.theme.colors.textPrimary};
  @media (max-width: 810px) {
    br {
      display: none;
    }
  }
`;
export const ContactWrapper = styled(RowCustom)`
  align-items: center;
  margin: 1rem 0rem;
`;
export const ColWrapper10 = styled(Box)`
  width: 10%;
  padding: 0rem;
`;
export const ColWrapper90 = styled(Box)`
  width: 90%;
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.textPrimary};
    font-size: 1.6rem;
    font-weight: 500;
    font-family: Avenir Roman;
    :hover {
      color: ${(props) => props.theme.colors.textTertiary};
    }
  }
`;
export const TextArea = styled.textarea`
  width: 100%;
  border: none;
  border-radius: 0px;
  padding: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme }) => theme.colors.footerLinksColor};
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  resize: none;

  &:focus {
    color: ${({ theme }) => theme.colors.textPrimary};
    border: 1px solid ${({ theme }) => theme.colors.textTertiary};
    background-color: ${({ theme }) => theme.colors.footerLinksColor};
    box-shadow: none;
  }

  scrollbar-width: thin;
  scrollbar-color: #030b1e ${({ theme }) => theme.colors.footerBackground};
  &::-webkit-scrollbar {
    ::-webkit-scrollbar {
      width: 12px;
      border-radius: 50%;
    }
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.footerBackground};
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.footerBackground};
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.colors.footerBackground};
  }
`;

export const FormErrorMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.error};
  font-size: 1.3rem;
  font-weight: 500;
  font-family: Avenir Roman;
`;
export const RealTimeMessage = styled(Text)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 1rem;
  font-weight: 500;
  font-family: Avenir Roman;
  min-height: 2rem;

  .invalid {
    border: 1px solid ${({ theme }) => theme.colors.error};
  }
  .error-message {
    text-align: left;
    margin-left: 1rem;
  }
`;
export const CustomRow = styled(Row)`
  margin: 0rem;
  > hr {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;
export const MblCustomRow = styled(Row)`
  margin: 2rem 0;
  justify-content: center;
`;
export const MblPolicySection = styled(Row)`
  justify-content: space-between;
`;
