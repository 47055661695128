import axios from "state/axios/interceptors";
import { REACT_APP_APIS } from "config/constants/endpoints";
import { IUser } from "../types";

/**
 * Fetch Projects to list.
 */
export const resetPasswordUser = async (
	email: string,
	password: string,
	code: string
): Promise<IUser> => {
	try {
		const { data } = await axios({
			data: {
				email,
				password,
				code,
			},
			url: `${REACT_APP_APIS}/user/reset-password`,
			// headers: { "content-type": "application/x-www-form-urlencoded" },
			method: "POST",
		});
		console.info(data, "---- POST Express");
		return {
			...data.user,
			jwt: data.token,
		};
	} catch (error) {
		console.error("Failed to post user express interest", error);
		throw error;
	}
};
