import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const GoldMedal: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_d_3381_12762)">
                <path d="M12.5282 2.98969C12.4077 2.78888 12.1907 2.66602 11.9565 2.66602H5.51144C4.99325 2.66602 4.67317 3.23133 4.93978 3.67568L12.1398 15.6757C12.2603 15.8765 12.4773 15.9993 12.7114 15.9993H19.1565C19.6747 15.9993 19.9948 15.434 19.7282 14.9897L12.5282 2.98969Z" fill="#C52229" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9568 2H5.51171C4.47532 2 3.83517 3.13063 4.36839 4.01933L11.5684 16.0193C11.8094 16.4209 12.2434 16.6667 12.7117 16.6667H19.1568C20.1932 16.6667 20.8333 15.536 20.3001 14.6473L13.1001 2.64734C12.8592 2.24573 12.4251 2 11.9568 2ZM11.9568 2.66667C12.191 2.66667 12.408 2.78953 12.5285 2.99034L19.7285 14.9903C19.9951 15.4347 19.675 16 19.1568 16H12.7117C12.4775 16 12.2605 15.8771 12.1401 15.6763L4.94005 3.67633C4.67344 3.23198 4.99352 2.66667 5.51171 2.66667H11.9568Z" fill="#9B0F15" />
                <path d="M19.4718 2.98969C19.5923 2.78888 19.8093 2.66602 20.0435 2.66602H26.4886C27.0068 2.66602 27.3268 3.23133 27.0602 3.67568L19.8602 15.6757C19.7397 15.8765 19.5227 15.9993 19.2886 15.9993H12.8435C12.3253 15.9993 12.0052 15.434 12.2718 14.9897L19.4718 2.98969Z" fill="#F1333B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.0432 2H26.4883C27.5247 2 28.1648 3.13063 27.6316 4.01933L20.4316 16.0193C20.1906 16.4209 19.7566 16.6667 19.2883 16.6667H12.8432C11.8068 16.6667 11.1667 15.536 11.6999 14.6473L18.8999 2.64734C19.1408 2.24573 19.5749 2 20.0432 2ZM20.0432 2.66667C19.809 2.66667 19.592 2.78953 19.4715 2.99034L12.2715 14.9903C12.0049 15.4347 12.325 16 12.8432 16H19.2883C19.5225 16 19.7395 15.8771 19.8599 15.6763L27.0599 3.67633C27.3266 3.23198 27.0065 2.66667 26.4883 2.66667H20.0432Z" fill="#9B0F15" />
                <path fillRule="evenodd" clipRule="evenodd" d="M20.1648 3.04513C20.324 3.13757 20.3782 3.34156 20.2857 3.50077L17.2857 8.66743C17.1933 8.82664 16.9893 8.88076 16.8301 8.78832C16.6709 8.69588 16.6167 8.49188 16.7092 8.33268L19.7092 3.16601C19.8016 3.00681 20.0056 2.95268 20.1648 3.04513Z" fill="#FE666D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.49923 3.04513C5.34003 3.13757 5.28591 3.34156 5.37835 3.50077L8.37835 8.66743C8.47079 8.82664 8.67479 8.88076 8.83399 8.78832C8.99319 8.69588 9.04732 8.49188 8.95487 8.33268L5.95487 3.16601C5.86243 3.00681 5.65844 2.95268 5.49923 3.04513Z" fill="#DF3840" />
                <path d="M11.8333 3.75911C11.8333 3.99499 11.6468 4.1862 11.4167 4.1862C11.1865 4.1862 11 3.99499 11 3.75911C11 3.52324 11.1865 3.33203 11.4167 3.33203C11.6468 3.33203 11.8333 3.52324 11.8333 3.75911Z" fill="#AA161D" />
                <path d="M10.3686 3.86328C10.3686 4.15668 10.1366 4.39453 9.85032 4.39453C9.56408 4.39453 9.33203 4.15668 9.33203 3.86328C9.33203 3.56988 9.56408 3.33203 9.85032 3.33203C10.1366 3.33203 10.3686 3.56988 10.3686 3.86328Z" fill="#AA161D" />
                <path d="M11.6655 5.38477C11.6655 5.67817 11.4334 5.91602 11.1472 5.91602C10.861 5.91602 10.6289 5.67817 10.6289 5.38477C10.6289 5.09136 10.861 4.85352 11.1472 4.85352C11.4334 4.85352 11.6655 5.09136 11.6655 5.38477Z" fill="#AA161D" />
                <path d="M12.8503 4.66016C12.8503 4.80686 12.7343 4.92578 12.5912 4.92578C12.4481 4.92578 12.332 4.80686 12.332 4.66016C12.332 4.51346 12.4481 4.39453 12.5912 4.39453C12.7343 4.39453 12.8503 4.51346 12.8503 4.66016Z" fill="#AA161D" />
                <path d="M10.112 5.11914C10.112 5.26584 9.99602 5.38477 9.8529 5.38477C9.70977 5.38477 9.59375 5.26584 9.59375 5.11914C9.59375 4.97244 9.70977 4.85352 9.8529 4.85352C9.99602 4.85352 10.112 4.97244 10.112 5.11914Z" fill="#AA161D" />
                <path d="M8.66673 3.49414C8.66673 3.64084 8.55071 3.75977 8.40758 3.75977C8.26446 3.75977 8.14844 3.64084 8.14844 3.49414C8.14844 3.34744 8.26446 3.22852 8.40758 3.22852C8.55071 3.22852 8.66673 3.34744 8.66673 3.49414Z" fill="#AA161D" />
                <path d="M12.5925 6.51562C12.5925 6.66233 12.4765 6.78125 12.3334 6.78125C12.1902 6.78125 12.0742 6.66233 12.0742 6.51562C12.0742 6.36892 12.1902 6.25 12.3334 6.25C12.4765 6.25 12.5925 6.36892 12.5925 6.51562Z" fill="#AA161D" />
                <path d="M11.2605 6.78125C11.2605 6.92795 11.1445 7.04687 11.0013 7.04687C10.8582 7.04687 10.7422 6.92795 10.7422 6.78125C10.7422 6.63455 10.8582 6.51562 11.0013 6.51562C11.1445 6.51562 11.2605 6.63455 11.2605 6.78125Z" fill="#AA161D" />
                <path d="M13.8435 5.81706C13.8435 6.05581 13.6547 6.24935 13.4217 6.24935C13.1888 6.24935 13 6.05581 13 5.81706C13 5.57831 13.1888 5.38477 13.4217 5.38477C13.6547 5.38477 13.8435 5.57831 13.8435 5.81706Z" fill="#AA161D" />
                <path d="M14.3933 7.04688C14.3933 7.19358 14.2773 7.3125 14.1341 7.3125C13.991 7.3125 13.875 7.19358 13.875 7.04688C13.875 6.90017 13.991 6.78125 14.1341 6.78125C14.2773 6.78125 14.3933 6.90017 14.3933 7.04688Z" fill="#AA161D" />
                <path d="M25 20.332C25 25.3026 20.9706 29.332 16 29.332C11.0294 29.332 7 25.3026 7 20.332C7 15.3615 11.0294 11.332 16 11.332C20.9706 11.332 25 15.3615 25 20.332Z" fill="#FFBB0D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.6654 20.3327C25.6654 25.6714 21.3375 29.9993 15.9987 29.9993C10.6599 29.9993 6.33203 25.6714 6.33203 20.3327C6.33203 14.9939 10.6599 10.666 15.9987 10.666C21.3375 10.666 25.6654 14.9939 25.6654 20.3327ZM15.9987 29.3327C20.9693 29.3327 24.9987 25.3032 24.9987 20.3327C24.9987 15.3621 20.9693 11.3327 15.9987 11.3327C11.0281 11.3327 6.9987 15.3621 6.9987 20.3327C6.9987 25.3032 11.0281 29.3327 15.9987 29.3327Z" fill="#E89B05" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7.66536 20.666C7.48127 20.666 7.33203 20.5168 7.33203 20.3327C7.33203 15.5462 11.2122 11.666 15.9987 11.666C16.1828 11.666 16.332 11.8153 16.332 11.9994C16.332 12.1834 16.1828 12.3327 15.9987 12.3327C11.5804 12.3327 7.9987 15.9144 7.9987 20.3327C7.9987 20.5168 7.84946 20.666 7.66536 20.666Z" fill="#E8AA0A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.3307 20C24.5148 20 24.6641 20.1492 24.6641 20.3333C24.6641 25.1198 20.7839 29 15.9974 29C15.8133 29 15.6641 28.8508 15.6641 28.6667C15.6641 28.4826 15.8133 28.3333 15.9974 28.3333C20.4157 28.3333 23.9974 24.7516 23.9974 20.3333C23.9974 20.1492 24.1466 20 24.3307 20Z" fill="#E8AA0A" />
                <path d="M22.6654 20.3327C22.6654 24.0146 19.6806 26.9993 15.9987 26.9993C12.3168 26.9993 9.33203 24.0146 9.33203 20.3327C9.33203 16.6508 12.3168 13.666 15.9987 13.666C19.6806 13.666 22.6654 16.6508 22.6654 20.3327Z" fill="#FFDD28" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23.3307 20.3333C23.3307 24.3834 20.0475 27.6667 15.9974 27.6667C11.9473 27.6667 8.66406 24.3834 8.66406 20.3333C8.66406 16.2832 11.9473 13 15.9974 13C20.0475 13 23.3307 16.2832 23.3307 20.3333ZM15.9974 27C19.6793 27 22.6641 24.0152 22.6641 20.3333C22.6641 16.6514 19.6793 13.6667 15.9974 13.6667C12.3155 13.6667 9.33073 16.6514 9.33073 20.3333C9.33073 24.0152 12.3155 27 15.9974 27Z" fill="#E89B05" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.9987 25.9993C19.1283 25.9993 21.6654 23.4623 21.6654 20.3327C21.6654 20.1486 21.8146 19.9993 21.9987 19.9993C22.1828 19.9993 22.332 20.1486 22.332 20.3327C22.332 23.8305 19.4965 26.666 15.9987 26.666C15.8146 26.666 15.6654 26.5168 15.6654 26.3327C15.6654 26.1486 15.8146 25.9993 15.9987 25.9993Z" fill="#ECC704" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.9974 14.6667C12.8678 14.6667 10.3307 17.2037 10.3307 20.3333C10.3307 20.5174 10.1815 20.6667 9.9974 20.6667C9.8133 20.6667 9.66406 20.5174 9.66406 20.3333C9.66406 16.8355 12.4996 14 15.9974 14C16.1815 14 16.3307 14.1492 16.3307 14.3333C16.3307 14.5174 16.1815 14.6667 15.9974 14.6667Z" fill="#FFEF9C" />
                <path d="M16.7969 23H15.668V18.6484L14.3203 19.0664V18.1484L16.6758 17.3047H16.7969V23Z" fill="#FFEF9C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.4622 23.0007C17.4622 23.3688 17.1638 23.6673 16.7956 23.6673H15.6667C15.2985 23.6673 15 23.3688 15 23.0007V19.5538L14.5165 19.7038C14.3142 19.7666 14.0941 19.7294 13.9235 19.6038C13.753 19.4781 13.6523 19.2789 13.6523 19.0671V18.1491C13.6523 17.8676 13.8292 17.6164 14.0942 17.5215L16.4497 16.6777C16.5218 16.6519 16.5979 16.6387 16.6745 16.6387H16.7956C17.1638 16.6387 17.4622 16.9371 17.4622 17.3053V23.0007ZM15.6667 18.6491V23.0007H16.7956V17.3053H16.6745L14.319 18.1491V19.0671L15.6667 18.6491Z" fill="#8C5D02" />
            </g>
            <defs>
                <filter id="filter0_d_3381_12762" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.637788 0 0 0 0 0.545996 0 0 0 0 0.47948 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3381_12762" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3381_12762" result="shape" />
                </filter>
            </defs>
        </Svg>
    )
}
export default GoldMedal
