import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../../index'
import { toggleTheme as toggleThemeAction, setUser as setUserAction, setUserBalance as setUserBalanceAction, setEventName } from '../actions'
import { IUser } from '../types'

export function useThemeManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>()
  const isDark = useSelector<AppState, AppState['user']['isDark']>((state) => state.user.isDark)

  const toggleTheme = useCallback(() => {
    dispatch(toggleThemeAction())
  }, [dispatch])

  return [isDark, toggleTheme]
}

export function useUserManager(): [IUser, (IUser) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector<AppState, AppState['user']['info']>((state) => state.user.info)

  const setUser = useCallback(
    (_user: IUser) => {
      dispatch(setUserAction(_user))
    },
    [dispatch],
  )

  return [user, setUser]
}
export function useUserBalance(): [number, (_balance) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const balance = useSelector<AppState, AppState['user']['balance']>((state) => state.user.balance)

  const setUserBalance = useCallback(
    (_balance: number) => {
      dispatch(setUserBalanceAction(_balance))
    },
    [dispatch],
  )

  return [balance, setUserBalance]
}
export function useEventName(): [string, (_eventname) => void] {
  const dispatch = useDispatch<AppDispatch>()
  const currenteventname = useSelector<AppState, AppState['user']['eventname']>((state) => state.user.eventname)

  const setUserEventName = useCallback(
    (_eventname: string) => {
      dispatch(setEventName(_eventname))
    },
    [dispatch],
  )

  return [currenteventname, setUserEventName]
}