import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./routerHistory";
import GlobalStyle from "./style/Global";
import PageLoader from "./components/PageLoader";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import Header from "components/Header";
import Footer from "components/Footer";
import "./fonts/Avenir/styles.css";

import { useUserBalance, useUserManager } from "state/user/hooks";
import { routes } from "config/constants/AppRoutes";
import { getUserTotalScore } from "service/apiRequest.service.request";
import { Text } from "components/Text";
import { Box } from "components/Box";
import TagManager from 'react-gtm-module'

const App: React.FC = () => {
  const [user] = useUserManager();
  const [, setUserBalance] = useUserBalance();
  useEffect(() => {
    const fetchBalance = async () => {
      const totalScore = await getUserTotalScore();
      setUserBalance(totalScore);
    };
    user && fetchBalance();
    //eslint-disable-next-line
  }, [user]);

  // GTM Tag Manager.
  const tagManagerArgs = { gtmId: process.env?.REACT_APP_GTM_KEY }
  TagManager.initialize(tagManagerArgs)

  const routeComponents = routes.map(
    ({
      route,
      component: Component,
      exact,
      isHeaderShow,
      isFooterShow,
      isAuth,
    }) => (
      <Route path={route} exact={exact} key="">
        {!isAuth || user ? (
          <>
            {isHeaderShow && <Header />}
            <SuspenseWithChunkError fallback={<PageLoader />}>
              <Component />
              {isFooterShow && <Footer />}
            </SuspenseWithChunkError>
          </>
        ) : (
          <Redirect to="/" />
        )}
      </Route>
    )
  );

  return (
    <Router history={history}>
      <GlobalStyle />

      <Switch>
        {routeComponents}
        <Route path="*">
          <Box height="100vh" display="grid" style={{ placeItems: "center" }}>
            <Text
              fontSize="3rem"
              fontWeight={600}
              color="white"
              textAlign="center"
            >
              404 | Page not found!
            </Text>
          </Box>
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
