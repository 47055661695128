import React from 'react'
import { Col } from "react-bootstrap";
import { Flex } from 'components/Box';
import { FooterSubHeading, QuickLinks } from 'components/Footer/styles';

const PrivacyPolicy: React.FC = () => {
    return (
        <Col xs={6} sm={3}>
            <Flex>
                <QuickLinks
                    href={`${process.env?.REACT_APP_PRIVACY_POLICY_LINK}`} rel="noopener noreferrer" target="_blank">
                    <FooterSubHeading>Privacy Policy</FooterSubHeading>
                </QuickLinks>
            </Flex>
        </Col>
    )
}
export default PrivacyPolicy
