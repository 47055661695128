import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Avenir Roman';
  }
  body {
    background-color: ${({ theme }) => theme.colors.headerBackground};
    img {
      height: auto;
      max-width: 100%;
    }
  }

  /** Avenir Roman **/
@font-face {
	font-family: "Avenir Roman";
	font-weight: 100;
	font-style: normal;
	src: url("./assets/fonts/Avenir/Avenir-Roman.woff");
}

/* styling for hamburger */
    .navbar-light .navbar-toggler-icon {
    background-image: ${(props) =>
    `url(${props.theme.url.hamburger})`} !important;
    width: 2.6rem;
    height: 2.6rem;
    // margin: 0rem 2rem;
    border: none !important;
    outline: none !important;
  }
  .navbar-light .navbar-toggler {
  border: none !important;
}
button:focus:not(:focus-visible) {
  outline: transparent !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}

.width-complete {
   animation: increase-width 8.5s linear forwards;
}
.width-complete-b {
   animation: increase-width 15s linear forwards;
}
.active {
    background: none !important;
  }
  //For nav background

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
      -webkit-text-fill-color: white;
      -webkit-box-shadow: 0 0 0px 1000px #8f39b8 inset;
  }

.subHeadingFont{
  font-weight: 800;
}

.content-outline{
  padding: 1rem;
  background: linear-gradient(90deg, #CD63FF10 -1645%, #8100BE10 5551%); 
  max-width: 210px;
}
.double-border{
  padding: 1rem;
  background: linear-gradient(90deg, #CD63FF10 -1645%, #8100BE10 5551%);  
}

.redeem-left-arrow{
  position: absolute;
  top: 40%;
  left: -10%;
  cursor: pointer;
  z-index: 1000;
}
.redeem-right-arrow{
  position: absolute;
  top: 40%;
  right: -10%;
  cursor: pointer;
  z-index: 1000;
}
@media (max-width:600px){
  .redeem-right-arrow{
    right: -10%;
  }
  .redeem-left-arrow{
    left: -10%;
  }  
}

@keyframes increase-width {
  100% {
    width: 100%;
  }
}

`;

export default GlobalStyle;
