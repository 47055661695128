import { Box, Flex } from "components/Box";
import { IconButton } from "components/Button";
import { Text } from "components/Text";
import styled from "styled-components";

export const Input = styled.input` 
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 0px;
    padding: 0 10px;
    font-size: 1.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textPrimary};
    background-color: ${({ theme }) => theme.colors.footerLinksColor};
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    position: relative;
    &:focus {
        color: ${({ theme }) => theme.colors.textPrimary};
        border: 1px solid ${({ theme }) => theme.colors.textTertiary};
        background-color: ${({ theme }) => theme.colors.footerLinksColor};
        box-shadow: none;
    }
`;

export const PasswordShowHide = styled(Box)`
    position: relative;
    .hideIcon{
        height: 50px;
        position: absolute;
        right: 1.5rem;
    }
`
export const HideShowIcon = styled.button`
    all: unset;
    cursor: pointer;
`

export const FormHeading = styled(Text)`
    font-family: Avenir Roman;
    font-size: 24px;
    font-weight: 900;
    margin: 2rem 0;
    color: ${({ theme }) => theme.colors.textPrimary};
`
export const ProfileHeading = styled(Text)`
    font-family: Avenir Roman;
    font-size: 24px;
    font-weight: 900;
    margin: 0 0 2rem 0;
    color: ${({ theme }) => theme.colors.textPrimary};
`
export const SocialHeading = styled(Text)`
    font-family: Avenir Roman;
    font-size:1.5rem;
    font-weight: 900;
    margin-left: 15px;
    color: ${({ theme }) => theme.colors.textPrimary};
`

export const FormSubHeading = styled(Text)`
    font-family: Avenir Roman;
    font-size: 14px;
    font-weight: 400;
    margin: 1rem 0rem;
    color: ${({ theme }) => theme.colors.textPrimary};
`
export const FormForgotText = styled(FormSubHeading)`
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: 4rem;
`
export const FormSocialHeading = styled(Text)`
    font-weight: 0;
    font-family: Avenir Roman;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.textPrimary};
`
export const FormTriheading = styled(FormSubHeading)`
    margin: 0 0 0rem 1rem;
    color: ${({ theme }) => theme.colors.textTertiary};
`
export const FormCheckBox = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    min-height: 65px;
    justify-content: space-between;

    input {
        border: 1px solid ${({ theme }) => theme.colors.textTertiary};
    }
`
export const CheckboxWrapper = styled(Flex)`
    min-height: unset;
    align-items: start;
    flex-direction: column;
`
export const FormAnchor = styled.a`
    color: ${({ theme }) => theme.colors.textTertiary};
    text-decoration: none;
    font-size: 14px;
    :hover {
        color: ${({ theme }) => theme.colors.textPrimary};
    }
`
export const SignuPolicy = styled(FormAnchor)`
    text-decoration: none;
`

export const CenterDiv = styled.div`
    text-align: center;
`
export const FormButton = styled(IconButton)`
    width: 100%;
    margin: 0;
`

export const SocialBody = styled.div`
    justify-content: space-between;
    display: flex;
    width: 100%;
    .fb-btn{
        width: 45%;
    background-color: ${({ theme }) => theme.colors.footerLinksColor};
    border-radius: 0px;
    display: flex;
    // justify-content: space-evenly;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-family: Avenir Roman;
    font-size:1.5rem;
    font-weight: 900;
    // margin-left: 15px;
    border: none;
    color: ${({ theme }) => theme.colors.textPrimary};
    img{
        margin-right: 15px;
    }
    }
`
export const SocialIcon = styled.div`
    width: 45%;
    background-color: ${({ theme }) => theme.colors.footerLinksColor};
    border-radius: 0px;
    display: flex;
    // justify-content: space-evenly;
    justify-content: center;
    align-items: center;
    height: 50px;
`
export const LogoDiv = styled.div`
    display: flex;
    justify-content: center;
`
export const ModalButton = styled.button`
    all: unset;
    color: ${({ theme }) => theme.colors.textTertiary};
    font-size: 14px;
    margin: 0px 0px 0px 5px;
`
export const VerifyBody = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
export const VerifyInput = styled(Input)`
    height: 55px;
    width: 60px;
`
export const ProfileImage = styled(Box)`
    background-color: ${({ theme }) => theme.colors.textSecondary};
    width: 9rem;
    height: 9rem;
    padding 0.5rem;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
    }
`