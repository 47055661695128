import { Form } from 'react-bootstrap'
import styled from 'styled-components'
import { space } from 'styled-system'

export const CheckBox = styled(Form.Check)`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  background-color: transparent;
  font-weight: 500;
  font-family: Avenir Roman;

  .form-check-input {
    border: 0.2rem solid ${(props) => props.theme.colors.textTertiary};
    background-color: transparent;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 0.5rem;
  }

  ${space}

  .form-check-input:checked[type=checkbox] {
    border-radius: 0.5rem;
    background-color: transparent;
    background-size: 100% 100%;
  }
  .form-check-input:checked[type='radio'] {
    border-radius: 0.5rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-color: transparent;
    border-color: ${(props) => props.theme.colors.textTertiary};
    background-size: 100% 100%;
  }
  .form-check-input:focus {
    box-shadow: none;
  }
  .form-check-label{
    color:white ;
    width:100% ;
  }
  &.withdraw-checkbox{
    width:100% ;
    flex-direction:row-reverse;
    justify-content:space-between ;
    padding-left:0 ;
  }
`