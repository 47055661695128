import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const INXIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
            <path d="M11.4447 0.0437984C8.9265 0.148853 6.4977 1.05985 4.51891 2.6388C4.03127 3.02766 3.47464 3.55293 3.06069 4.01549C1.39393 5.87354 0.343387 8.21297 0.070559 10.6716C0.0125438 11.1921 0 11.4336 0 12.0357C0 12.6378 0.0125438 12.8887 0.070559 13.3998C0.249309 15.0086 0.771446 16.6001 1.57739 18.0019C2.67184 19.9038 4.25393 21.4687 6.16843 22.5365C8.51726 23.8473 11.2722 24.3177 13.9534 23.8661C17.6648 23.2389 20.8745 20.8807 22.6024 17.5079C23.3786 15.9933 23.8035 14.4128 23.9038 12.6786C23.9242 12.3289 23.9148 11.4211 23.8882 11.1028C23.7486 9.47676 23.355 8.06715 22.6463 6.64969C22.0693 5.4988 21.3465 4.49372 20.437 3.57645C18.0568 1.17588 14.8174 -0.0973186 11.4447 0.0437984ZM12.9107 0.885803C13.8562 0.976746 14.6465 1.14138 15.4744 1.41892C16.031 1.60707 16.4544 1.78582 17.011 2.06963C20.3524 3.77716 22.6385 7.08716 23.0399 10.8001C23.0901 11.2674 23.1026 11.5167 23.1026 12.031C23.1026 12.8463 23.0352 13.5347 22.8815 14.2857C22.4158 16.5515 21.2257 18.6683 19.5401 20.2253C17.9879 21.66 16.1502 22.6039 14.1181 23.01C11.1421 23.6042 8.0061 22.9347 5.54437 21.177C4.9752 20.7709 4.63181 20.4808 4.12535 19.9791C2.35197 18.2245 1.22773 15.9666 0.901588 13.4986C0.791829 12.677 0.777717 11.7174 0.862388 10.8989C1.02859 9.28703 1.54916 7.70651 2.36608 6.3361C3.24572 4.86063 4.42954 3.61879 5.83915 2.68898C7.48867 1.60237 9.29184 0.998697 11.2879 0.863852C11.5999 0.843468 12.6081 0.85758 12.9107 0.885803Z" fill="white" />
            <path d="M10.5881 11.0488C10.5289 10.8571 10.4472 10.6854 10.343 10.5339C10.2412 10.38 10.1181 10.2486 9.97372 10.1397C9.83168 10.0308 9.66832 9.94916 9.48366 9.89471C9.29901 9.83789 9.09777 9.80948 8.87997 9.80948C8.48935 9.80948 8.14134 9.90773 7.83594 10.1042C7.53054 10.3007 7.29025 10.5895 7.11506 10.9707C6.94223 11.3495 6.85582 11.8111 6.85582 12.3556C6.85582 12.9049 6.94223 13.3701 7.11506 13.7512C7.28788 14.1324 7.52817 14.4224 7.83594 14.6213C8.1437 14.8178 8.50118 14.916 8.90838 14.916C9.2777 14.916 9.5973 14.845 9.86719 14.7029C10.1394 14.5609 10.349 14.3597 10.4957 14.0993C10.6425 13.8365 10.7159 13.5287 10.7159 13.176L11.0142 13.2221H9.03977V12.1923H11.9908V13.0659C11.9908 13.6885 11.8582 14.2271 11.593 14.6816C11.3279 15.1362 10.9633 15.4866 10.4993 15.7328C10.0353 15.9766 9.5026 16.0985 8.90128 16.0985C8.2313 16.0985 7.64299 15.9482 7.13636 15.6476C6.6321 15.3445 6.23793 14.9148 5.95384 14.3585C5.67211 13.7998 5.53125 13.1369 5.53125 12.3699C5.53125 11.7827 5.61411 11.2583 5.77983 10.7967C5.94792 10.3351 6.18229 9.94324 6.48295 9.62127C6.78362 9.29693 7.13636 9.05072 7.54119 8.88263C7.94602 8.71218 8.38636 8.62695 8.86222 8.62695C9.26468 8.62695 9.63992 8.68614 9.98793 8.80451C10.3359 8.92051 10.6449 9.08623 10.9148 9.30167C11.187 9.5171 11.4107 9.77279 11.5859 10.0687C11.7611 10.3646 11.8759 10.6913 11.9304 11.0488H10.5881Z" fill="white" />
            <path d="M13.2647 15.9991V8.72638H17.9238V9.83079H14.5822V11.8052H17.6042V12.9096H14.5822V15.9991H13.2647Z" fill="white" />
        </Svg>
    )
}
export default INXIcon
