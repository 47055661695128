import { scales, variants } from "./types";
import primaryBtnBackground from "../../assets/images/button-primary.png"
import secondaryBtnBackground from "../../assets/images/button-secondary.png"

export const scaleVariants = {
  [scales.MD]: {
    height: "48px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
   [variants.PRIMARY]: {
    backgroundImage: `url(${primaryBtnBackground})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    color: 'textPrimary',
    cursor: 'pointer',
    borderRadius: '0rem',
    '&:disabled': {
      backgroundColor: 'none !important',
      border: 'none !important',
      cusror: 'not-allowed'
    }
  },
  [variants.SECONDARY]: {
    backgroundImage: `url(${secondaryBtnBackground})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    color: 'textPrimary',
    cursor: 'pointer',
    borderRadius: '0rem',
    '&:disabled': {
      backgroundColor: 'none !important',
      border: 'none !important',
      cusror: 'not-allowed'
    }
  },
  [variants.TERTIARY]: {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "primary",
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "white",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",
  },
};
