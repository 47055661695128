import styled from "styled-components";
import { Box } from "../Box";

const Wrapper = styled(Box)`
  margin-top: 12rem;
  min-height: calc(100vh - 18rem);
  /* @media (max-width: 991px) {
    margin-top: 12rem;
  }
  @media (max-width: 576px) {
    margin-top: 12rem;
  } */
`;
export default Wrapper;
