import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const SilverMedal: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="32" height="32" viewBox="0 0 32 32" {...props}>
            <g filter="url(#filter0_d_3381_12768)">
                <path d="M12.3521 2.99034C12.2317 2.78953 12.0146 2.66667 11.7805 2.66667H5.33539C4.8172 2.66667 4.49712 3.23198 4.76373 3.67633L11.9637 15.6763C12.0842 15.8771 12.3012 16 12.5354 16H18.9805C19.4987 16 19.8187 15.4347 19.5521 14.9903L12.3521 2.99034Z" fill="#C52229" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.7805 2H5.33539C4.299 2 3.65885 3.13063 4.19207 4.01933L11.3921 16.0193C11.633 16.4209 12.067 16.6667 12.5354 16.6667H18.9805C20.0169 16.6667 20.657 15.536 20.1238 14.6473L12.9238 2.64734C12.6828 2.24573 12.2488 2 11.7805 2ZM11.7805 2.66667C12.0146 2.66667 12.2317 2.78953 12.3521 2.99034L19.5521 14.9903C19.8187 15.4347 19.4987 16 18.9805 16H12.5354C12.3012 16 12.0842 15.8771 11.9637 15.6763L4.76373 3.67633C4.49712 3.23198 4.8172 2.66667 5.33539 2.66667H11.7805Z" fill="#9B0F15" />
                <path d="M19.2971 2.99034C19.4175 2.78953 19.6346 2.66667 19.8687 2.66667H26.3138C26.832 2.66667 27.1521 3.23198 26.8855 3.67633L19.6855 15.6763C19.565 15.8771 19.348 16 19.1138 16H12.6687C12.1505 16 11.8305 15.4347 12.0971 14.9903L19.2971 2.99034Z" fill="#F1333B" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.8687 2H26.3138C27.3502 2 27.9904 3.13063 27.4571 4.01933L20.2571 16.0193C20.0162 16.4209 19.5822 16.6667 19.1138 16.6667H12.6687C11.6323 16.6667 10.9922 15.536 11.5254 14.6473L18.7254 2.64734C18.9664 2.24573 19.4004 2 19.8687 2ZM19.8687 2.66667C19.6346 2.66667 19.4175 2.78953 19.2971 2.99034L12.0971 14.9903C11.8305 15.4347 12.1505 16 12.6687 16H19.1138C19.348 16 19.565 15.8771 19.6855 15.6763L26.8855 3.67633C27.1521 3.23198 26.832 2.66667 26.3138 2.66667H19.8687Z" fill="#9B0F15" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.992 3.04507C20.1512 3.13751 20.2053 3.34151 20.1129 3.50071L17.1129 8.66738C17.0204 8.82658 16.8164 8.8807 16.6572 8.78826C16.498 8.69582 16.4439 8.49183 16.5363 8.33262L19.5363 3.16595C19.6288 3.00675 19.8328 2.95263 19.992 3.04507Z" fill="#FE666D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.32389 3.04507C5.16468 3.13751 5.11056 3.34151 5.203 3.50071L8.203 8.66738C8.29544 8.82658 8.49944 8.8807 8.65864 8.78826C8.81785 8.69582 8.87197 8.49183 8.77953 8.33262L5.77953 3.16595C5.68709 3.00675 5.48309 2.95263 5.32389 3.04507Z" fill="#DF3840" />
                <path d="M11.6579 3.76042C11.6579 3.99629 11.4714 4.1875 11.2413 4.1875C11.0111 4.1875 10.8246 3.99629 10.8246 3.76042C10.8246 3.52455 11.0111 3.33333 11.2413 3.33333C11.4714 3.33333 11.6579 3.52455 11.6579 3.76042Z" fill="#AA161D" />
                <path d="M10.1945 3.86458C10.1945 4.15798 9.96247 4.39583 9.67623 4.39583C9.38998 4.39583 9.15793 4.15798 9.15793 3.86458C9.15793 3.57118 9.38998 3.33333 9.67623 3.33333C9.96247 3.33333 10.1945 3.57118 10.1945 3.86458Z" fill="#AA161D" />
                <path d="M11.4913 5.38542C11.4913 5.67882 11.2592 5.91667 10.973 5.91667C10.6867 5.91667 10.4547 5.67882 10.4547 5.38542C10.4547 5.09202 10.6867 4.85417 10.973 4.85417C11.2592 4.85417 11.4913 5.09202 11.4913 5.38542Z" fill="#AA161D" />
                <path d="M12.6762 4.66146C12.6762 4.80816 12.5602 4.92708 12.4171 4.92708C12.274 4.92708 12.1579 4.80816 12.1579 4.66146C12.1579 4.51476 12.274 4.39583 12.4171 4.39583C12.5602 4.39583 12.6762 4.51476 12.6762 4.66146Z" fill="#AA161D" />
                <path d="M9.93537 5.11979C9.93537 5.26649 9.81935 5.38542 9.67622 5.38542C9.5331 5.38542 9.41708 5.26649 9.41708 5.11979C9.41708 4.97309 9.5331 4.85417 9.67622 4.85417C9.81935 4.85417 9.93537 4.97309 9.93537 5.11979Z" fill="#AA161D" />
                <path d="M8.49127 3.49479C8.49127 3.64149 8.37524 3.76042 8.23212 3.76042C8.089 3.76042 7.97298 3.64149 7.97298 3.49479C7.97298 3.34809 8.089 3.22917 8.23212 3.22917C8.37524 3.22917 8.49127 3.34809 8.49127 3.49479Z" fill="#AA161D" />
                <path d="M12.4171 6.51562C12.4171 6.66233 12.3011 6.78125 12.1579 6.78125C12.0148 6.78125 11.8988 6.66233 11.8988 6.51562C11.8988 6.36892 12.0148 6.25 12.1579 6.25C12.3011 6.25 12.4171 6.36892 12.4171 6.51562Z" fill="#AA161D" />
                <path d="M11.0837 6.78125C11.0837 6.92795 10.9677 7.04688 10.8246 7.04688C10.6815 7.04688 10.5655 6.92795 10.5655 6.78125C10.5655 6.63455 10.6815 6.51562 10.8246 6.51562C10.9677 6.51562 11.0837 6.63455 11.0837 6.78125Z" fill="#AA161D" />
                <path d="M13.6681 5.81771C13.6681 6.05646 13.4793 6.25 13.2463 6.25C13.0134 6.25 12.8246 6.05646 12.8246 5.81771C12.8246 5.57896 13.0134 5.38542 13.2463 5.38542C13.4793 5.38542 13.6681 5.57896 13.6681 5.81771Z" fill="#AA161D" />
                <path d="M14.2179 7.04688C14.2179 7.19358 14.1019 7.3125 13.9587 7.3125C13.8156 7.3125 13.6996 7.19358 13.6996 7.04688C13.6996 6.90017 13.8156 6.78125 13.9587 6.78125C14.1019 6.78125 14.2179 6.90017 14.2179 7.04688Z" fill="#AA161D" />
                <path d="M24.8229 20.3327C24.8229 25.3032 20.7935 29.3327 15.8229 29.3327C10.8524 29.3327 6.82292 25.3032 6.82292 20.3327C6.82292 15.3621 10.8524 11.3327 15.8229 11.3327C20.7935 11.3327 24.8229 15.3621 24.8229 20.3327Z" fill="#BCCCD2" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.4896 20.3327C25.4896 25.6714 21.1617 29.9993 15.8229 29.9993C10.4842 29.9993 6.15625 25.6714 6.15625 20.3327C6.15625 14.9939 10.4842 10.666 15.8229 10.666C21.1617 10.666 25.4896 14.9939 25.4896 20.3327ZM15.8229 29.3327C20.7935 29.3327 24.8229 25.3032 24.8229 20.3327C24.8229 15.3621 20.7935 11.3327 15.8229 11.3327C10.8524 11.3327 6.82292 15.3621 6.82292 20.3327C6.82292 25.3032 10.8524 29.3327 15.8229 29.3327Z" fill="#81A5B0" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7.48958 20.666C7.30549 20.666 7.15625 20.5168 7.15625 20.3327C7.15625 15.5462 11.0364 11.666 15.8229 11.666C16.007 11.666 16.1563 11.8153 16.1563 11.9993C16.1563 12.1834 16.007 12.3327 15.8229 12.3327C11.4046 12.3327 7.82292 15.9144 7.82292 20.3327C7.82292 20.5168 7.67368 20.666 7.48958 20.666Z" fill="#D0DEE3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.1562 19.9993C24.3403 19.9993 24.4896 20.1486 24.4896 20.3327C24.4896 25.1192 20.6094 28.9993 15.8229 28.9993C15.6388 28.9993 15.4896 28.8501 15.4896 28.666C15.4896 28.4819 15.6388 28.3327 15.8229 28.3327C20.2412 28.3327 23.8229 24.751 23.8229 20.3327C23.8229 20.1486 23.9722 19.9993 24.1562 19.9993Z" fill="#A2B9C1" />
                <path d="M22.4896 20.3327C22.4896 24.0146 19.5048 26.9993 15.8229 26.9993C12.141 26.9993 9.15625 24.0146 9.15625 20.3327C9.15625 16.6508 12.141 13.666 15.8229 13.666C19.5048 13.666 22.4896 16.6508 22.4896 20.3327Z" fill="#C7D4D8" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23.1562 20.3327C23.1562 24.3828 19.873 27.666 15.8229 27.666C11.7728 27.666 8.48958 24.3828 8.48958 20.3327C8.48958 16.2826 11.7728 12.9993 15.8229 12.9993C19.873 12.9993 23.1562 16.2826 23.1562 20.3327ZM15.8229 26.9993C19.5048 26.9993 22.4896 24.0146 22.4896 20.3327C22.4896 16.6508 19.5048 13.666 15.8229 13.666C12.141 13.666 9.15625 16.6508 9.15625 20.3327C9.15625 24.0146 12.141 26.9993 15.8229 26.9993Z" fill="#81A5B0" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.8229 25.9993C18.9525 25.9993 21.4896 23.4623 21.4896 20.3327C21.4896 20.1486 21.6388 19.9993 21.8229 19.9993C22.007 19.9993 22.1562 20.1486 22.1562 20.3327C22.1562 23.8305 19.3207 26.666 15.8229 26.666C15.6388 26.666 15.4896 26.5168 15.4896 26.3327C15.4896 26.1486 15.6388 25.9993 15.8229 25.9993Z" fill="#B5C3C8" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.8229 14.666C12.6933 14.666 10.1562 17.2031 10.1562 20.3327C10.1562 20.5168 10.007 20.666 9.82292 20.666C9.63882 20.666 9.48958 20.5168 9.48958 20.3327C9.48958 16.8349 12.3251 13.9993 15.8229 13.9993C16.007 13.9993 16.1562 14.1486 16.1562 14.3327C16.1562 14.5168 16.007 14.666 15.8229 14.666Z" fill="#D9EAEF" />
                <path d="M16.3789 18.5039C16.4362 18.6263 16.4648 18.7734 16.4648 18.9453C16.4648 19.0599 16.4401 19.181 16.3906 19.3086C16.3438 19.4336 16.2669 19.5755 16.1602 19.7344C16.0534 19.8906 15.9076 20.0716 15.7227 20.2773L16.3789 18.5039ZM16.3789 18.5039C16.3216 18.3789 16.237 18.2826 16.125 18.2148L16.3789 18.5039ZM18.0734 22.1211C18.0734 21.9554 17.9391 21.8211 17.7734 21.8211H16.005L16.5432 21.2169C16.735 21.0223 16.9107 20.8333 17.0701 20.65C17.2349 20.4605 17.3773 20.2711 17.4961 20.0817C17.6191 19.8871 17.7159 19.6887 17.7842 19.4867L17.7846 19.4855C17.8546 19.2755 17.8898 19.0599 17.8898 18.8398C17.8898 18.4636 17.8088 18.1222 17.6335 17.8282C17.4595 17.5294 17.203 17.305 16.8774 17.1542C16.5534 17.0002 16.1711 16.9305 15.7422 16.9305C15.2964 16.9305 14.8941 17.0242 14.5466 17.2238C14.2075 17.416 13.9403 17.6792 13.7512 18.0113C13.5627 18.3399 13.4695 18.7015 13.4695 19.0898C13.4695 19.2555 13.6038 19.3898 13.7695 19.3898H14.8984C15.0641 19.3898 15.1984 19.2555 15.1984 19.0898C15.1984 18.9368 15.2237 18.8101 15.2678 18.7044C15.3121 18.6042 15.3706 18.5354 15.441 18.4871C15.5098 18.4416 15.598 18.4133 15.7188 18.4133C15.8386 18.4133 15.9159 18.4382 15.9671 18.4699L15.9671 18.4699L15.9698 18.4716C16.0276 18.5065 16.0726 18.5557 16.1062 18.6289L16.1062 18.6289L16.1072 18.6311C16.1415 18.7044 16.1648 18.8058 16.1648 18.9453C16.1648 19.0164 16.1495 19.1006 16.1109 19.2001L16.1109 19.2001L16.1097 19.2033C16.0738 19.299 16.0103 19.4194 15.9118 19.566C15.8155 19.7069 15.6797 19.8762 15.5014 20.0748L13.6634 22.0454C13.6116 22.1009 13.5828 22.174 13.5828 22.25V23C13.5828 23.1657 13.7171 23.3 13.8828 23.3H17.7734C17.9391 23.3 18.0734 23.1657 18.0734 23V22.1211Z" fill="#D9EAEF" stroke="#25505E" strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <filter id="filter0_d_3381_12768" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="1" dy="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.637788 0 0 0 0 0.545996 0 0 0 0 0.47948 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3381_12768" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3381_12768" result="shape" />
                </filter>
            </defs>
        </Svg>
    );
};

export default SilverMedal;
