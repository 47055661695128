import React from "react";
import { Flex } from "components/Box";
import useTheme from "hooks/useTheme";
import useViewport from "hooks/useViewport";
interface followUSType {
  image: JSX.Element;
  link: string;
}
const FollowUsSingle: React.FC<followUSType> = (props) => {
  const { image, link } = props;
  const { theme } = useTheme();
  const { width } = useViewport();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width={width < 767 ? "5rem" : "4rem"}
      height={width < 767 ? "5rem" : "4rem"}
      marginRight="1.4rem"
      background={theme.colors.footerLinksColor}
      style={{ cursor: "pointer" }}
      onClick={() => window.open(`${link}`)}
    >
      <div>
        {image}
      </div>
    </Flex>
  );
};
export default FollowUsSingle;
