import axios from "state/axios/interceptors";
import { REACT_APP_APIS } from "config/constants/endpoints";
import qs from "qs";
import { IUser } from "../types";

/**
 * Fetch Projects to list.
 */
export const loginUser = async (
	email: string,
	password: string
): Promise<IUser> => {
	try {
		const { data } = await axios({
			data: qs.stringify({
				email,
				password,
			}),
			url: `${REACT_APP_APIS}/user/login`,
			headers: { "content-type": "application/x-www-form-urlencoded" },
			method: "POST",
		});
		console.info(data, "---- POST Express");
		localStorage.setItem("jwt", data.token);
		localStorage.setItem("referral_id", data.referral_id);
		return {
			...data.user,
			jwt: data.token,
			referral_id: data.referral_id,
		};
	} catch (error) {
		console.error("Failed to post user express interest", error);
		throw error;
	}
};
