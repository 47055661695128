import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ThemeContextProvider } from "contexts/ThemeContext";
import { RefreshContextProvider } from "contexts/RefreshContext";
import { ModalProvider } from "widgets/Modal";
import { ChildrenProps } from "widgets/Modal/types";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, useStore } from "state";

const Providers: React.FC<ChildrenProps> = ({ children }) => {
  const store = useStore()

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <ThemeContextProvider>
            <RefreshContextProvider>
              <ModalProvider>{children}</ModalProvider>
            </RefreshContextProvider>
          </ThemeContextProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
};

export default Providers;
