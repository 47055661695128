import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Avatar: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="42" height="42" viewBox="0 0 42 42" {...props}>
            <rect width="42" height="42" rx="21" fill="#CD63FF" />
            <g clipPath="url(#clip0_3244_10635)">
                <path d="M21 32.998C27.6274 32.998 33 27.6255 33 20.998C33 14.3706 27.6274 8.99805 21 8.99805C14.3726 8.99805 9 14.3706 9 20.998C9 27.6255 14.3726 32.998 21 32.998Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21 22.332C23.2091 22.332 25 20.5412 25 18.332C25 16.1229 23.2091 14.332 21 14.332C18.7909 14.332 17 16.1229 17 18.332C17 20.5412 18.7909 22.332 21 22.332Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.2266 30.1307C13.5566 29.0323 14.2319 28.0696 15.1522 27.3853C16.0726 26.7011 17.189 26.3317 18.3359 26.332H23.6692C24.8176 26.3316 25.9354 26.7019 26.8564 27.3877C27.7774 28.0736 28.4525 29.0384 28.7812 30.1387" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3244_10635">
                    <rect width="32" height="32" fill="white" transform="translate(5 5)" />
                </clipPath>
            </defs>
        </Svg>
    );
};

export default Avatar;
