import React, { useEffect, useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
    HeaderNavBar,
    // NavBarContainer,
    RouterNavLink,
    OffCanvasWrapper,
    OffcanvasCloseIcon,
    // RouterATag
} from "./styles";
import { Flex } from "components/Box";
import { Button } from "components/Button";
import useViewport from "hooks/useViewport";
import useTheme from "hooks/useTheme";
import { useModal } from "widgets/Modal";
import { SignupModal } from "views/Home/components/forms/components/SignupModal";
import { LoginModal } from "views/Home/components/forms/components/LoginModal";
import { useUserBalance, useUserManager } from "state/user/hooks";
import { LogoIcon, GamesFiIcon } from "components/Svg";
import { ContainerRow } from "components/ContainerCustom";
import { ResetPasswordModal } from "views/Home/components/forms/components/ResetPasswordModal";
import { useHistory } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Text } from "components/Text";
import userImage from 'assets/images/user.svg'
import NavbarSettings from "./NavbarSettings";
import { formatCash } from "utils/amountConverter";

const Header: React.FC = () => {
    const { width } = useViewport();
    const isMobile = width < 992
    const isWeb = width >= 992
    const { theme } = useTheme();
    const history = useHistory();
    const [user] = useUserManager();
    const [balance,] = useUserBalance();

    //For Login Modal
    const [showLoginModal, onLoginDismiss] = useModal(
        <LoginModal handleClose={() => onLoginDismiss()} />,
        true
    );
    //For Signup Modal
    const [showSignupModal, onSignupDismiss] = useModal(
        <SignupModal handleClose={() => onSignupDismiss()} />,
        true
    );

    //For Reset Password Modal
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get("code");
    const email = searchParams.get("email");

    const [showResetModal, onResetDismiss] = useModal(
        <ResetPasswordModal email={email} code={code} handleClose={() => onResetDismiss()} />,
        true
    );
    useEffect(() => {
        if (code && email) {
            showResetModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //For Navigation of ID's in Menus
    const { pathname, key, hash } = useLocation();
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === "") {
            // window.onload = function () {
            window.scrollTo(0, 0);
            // }
        }
        // else scroll to id
        else {
            const id = hash.replace("#", "");
            let element = document.getElementById(id);
            setTimeout(async () => {
                while (!element) {
                    await new Promise((r) => setTimeout(() => r(true), 10));
                    element = document.getElementById(id);
                }
                element.scrollIntoView(true);
                const topOfElement =
                    window.pageYOffset + element.getBoundingClientRect().top - 80;
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }, 1000);
        };

    }, [pathname, hash, key]); // do this on route change

    //For Toggle, Remove the Tabs.
    const [expanded, setExpanded] = useState(false);
    const toggleMenu = () => {
        isMobile && (expanded ? setExpanded(false) : setExpanded(true));
    };

    const canvasBgColor = "radial-gradient(74.5% 111.75% at 49.73% 106.8%,#3B0864 0%,#200942 39%,#0B0B28 77%,#030B1E 100%)";
    const canvasWidth = "80%";

    return (
        <HeaderNavBar bg="light" expand="lg" fixed="top" expanded={expanded}>
            <ContainerRow>
                {isMobile && (
                    <>
                        <Navbar.Toggle className="nav-body-toggle" aria-controls={`offcanvasNavbar-expand-${expanded}`} onClick={toggleMenu} />
                        <Navbar.Toggle onClick={toggleMenu} aria-controls={`offcanvasNavbar-expand-${expanded}`} />
                        <Navbar.Brand className="NavlogoWidth">
                            <Link to="/"
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth"
                                    });
                                }}
                            >
                                <GamesFiIcon />
                            </Link>
                        </Navbar.Brand>
                        {user && <NavbarSettings toggleMenu={toggleMenu} isWeb={isWeb} />}
                    </>
                )
                }
                <Navbar.Offcanvas
                    show={expanded ? expanded : undefined} onHide={() => setExpanded(false)}
                    placement="start"
                    backdrop={false}
                    scroll={false}
                    id={`offcanvasNavbar-expand-${expanded}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expanded}`}
                    style={{ background: isMobile && canvasBgColor, maxWidth: isMobile && canvasWidth, border: 'none' }}
                >
                    <OffCanvasWrapper>
                        <OffcanvasCloseIcon closeButton />
                        <Offcanvas.Body>
                            {isMobile && user && <Flex alignItems="center" margin="2rem 0">
                                <img src={userImage} alt="logo" />
                                <Text>{user.email}</Text>
                            </Flex>}
                            <Flex flex={2}>
                                {!isMobile &&
                                    <Navbar.Brand>
                                        <Link to="/"
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: "smooth"
                                                });
                                            }}
                                        >
                                            <LogoIcon />
                                        </Link>
                                    </Navbar.Brand>}
                            </Flex>
                            {isMobile && user && <hr />}
                            <Flex flex={5} justifyContent={isMobile ? "start" : "center"} marginTop={isMobile ? '3rem' : ''}>
                                <Flex
                                    flexDirection={isMobile ? "column" : "row"}
                                    alignItems={isMobile ? "start" : "center"}
                                >
                                    <RouterNavLink to="/"
                                        onClick={() => {
                                            toggleMenu();
                                            window.scrollTo({
                                                top: 0,
                                                behavior: "smooth"
                                            });
                                        }}
                                    >Home</RouterNavLink>
                                    <RouterNavLink to={"/#games"} onClick={toggleMenu}>Games</RouterNavLink>
                                    <RouterNavLink to={"/#redeem"} onClick={toggleMenu}>Redeem on Binance</RouterNavLink>
                                    {/* <RouterATag href={`${process.env?.REACT_APP_MEDIUM_LINK}`} rel="noopener noreferrer" target="_blank">Blog</RouterATag>
              <RouterNavLink to="/">FAQ</RouterNavLink> */}
                                    <RouterNavLink to={"/#events"} onClick={() => {
                                        toggleMenu();
                                        window.scrollTo({
                                            top: 0,
                                        });
                                    }}>Events</RouterNavLink>
                                    <RouterNavLink to={"#contactus"} onClick={toggleMenu}>Contact us</RouterNavLink>
                                </Flex>
                            </Flex>
                            {isMobile && <hr />}
                            <Flex flex={2} justifyContent={isMobile ? "start" : "flex-end"}>
                                <Flex
                                    justifyContent="space-between"
                                    flexDirection={isMobile ? "column" : "row"}
                                    alignItems={isMobile ? "start" : "center"}
                                >
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        maxWidth={user ? "20rem" : "15rem"}
                                        style={{ color: theme.colors.textTertiary }}
                                        margin={isMobile ? "2rem 0rem 0rem 0rem !important" : "0rem !important"}
                                        className={user ? "btn-disable" : ""}
                                        onClick={() => {
                                            toggleMenu();
                                            if (user) {
                                                return null;
                                            } else {
                                                showLoginModal();
                                            }
                                        }}
                                    >
                                        {user ? `Balance: ${formatCash(balance)} GF` : "LOGIN"}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        maxWidth="15rem"
                                        margin={
                                            isMobile ? "2rem 0rem !important" : "0rem 0rem 0rem 1rem !important"
                                        }
                                        onClick={() => {
                                            toggleMenu();
                                            if (user) {
                                                history.push("/withdraw");
                                            } else {
                                                showSignupModal();
                                            }
                                        }}
                                    >
                                        {user ? "Withdraw" : "SIGN UP"}
                                    </Button>

                                    {user && !isMobile && (<NavbarSettings toggleMenu={toggleMenu} isWeb={isWeb} />)}

                                </Flex>
                            </Flex>
                        </Offcanvas.Body>
                    </OffCanvasWrapper>
                </Navbar.Offcanvas>
            </ContainerRow >
        </HeaderNavBar >
    );
};

export default Header;