export type PageMeta = {
  title: string;
  description?: string;
  image?: string;
};
export enum ApplicationRoutes {
  HOME = "/",
  GAMES = "/#games",
  GAMEPLAY = "/games/gameplay",
  GAMECATEGORY = "/games/game/:category",
  GAMEDETAILS = "/games/:id",
  FORGOTPASSWORD = "/forgotpassword",
  WITHDRAW = "/withdraw",
  REFERRAL = "/referral",
  CONTACT = "/#contact",
  EVENTSHISTORY = "/eventshistory",
  EVENTS = "/#events",
  HOW_TO_REDEEM = "/#redeem",
}
