import React from "react";
import { ContainerRow } from "components/ContainerCustom";
import { Row, Col } from "react-bootstrap";
import { Flex, Box } from "components/Box";
import { Text } from "components/Text";
import { FooterLogoText } from "./styles";
import FollowUsSingle from "./components/FollowUsSingle";

import footerLogo from "../../assets/images/footer-logo.svg";
import useTheme from "hooks/useTheme";
import useViewport from "hooks/useViewport";
import { DiscordIcon, FacebookIcon, MediumIcon, TelegramIcon, TwitterIcon, YoutubeIcon } from "components/Svg";
import FooterBottom from "./components/FooterBottom";
import ContactUs from "./components/ContactUs";

const Footer: React.FC = () => {
    const { theme } = useTheme();
    const { width } = useViewport();
    return (
        <>
            <Box
                style={{
                    backgroundImage: `url(${theme.url.footerBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
                id="contactus"
            >
                <ContainerRow id="getInTouch">
                    <Row style={{ padding: "5rem 0rem" }}>
                        <Col lg={4}>
                            <Flex
                                flexDirection="column"
                                margin={width < 576 ? "0rem 0rem 2rem 0rem" : "0rem"}
                            >
                                <img
                                    src={footerLogo}
                                    alt="footer-logo"
                                    style={{ width: "28.8rem", height: "5.4rem" }}
                                />
                                <Flex margin="2rem 0rem 1rem 0rem">
                                    <FooterLogoText>
                                        Gamesfi provides Free to Play<br /> with Fun Games and Get Crypto..!
                                    </FooterLogoText>
                                </Flex>
                                <Box>
                                    <Row>
                                        <Flex>
                                            <Flex width="30%">
                                                <Flex alignItems="center" margin="2rem 0rem">
                                                    <Text
                                                        fontFamily="Avenir Roman"
                                                        fontSize="1.6rem"
                                                        fontWeight="500"
                                                        marginRight="2rem"
                                                        color={theme.colors.textPrimary}
                                                    >
                                                        Follow Us
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                            <Flex justifyContent="center" alignItems="center">
                                                <Flex
                                                    width="100%"
                                                    maxWidth="40rem"
                                                    justifyContent="flex-start"
                                                >
                                                    <FollowUsSingle image={<DiscordIcon />} link={`${process.env?.REACT_APP_DISCORD_LINK}`} />
                                                    <FollowUsSingle image={<MediumIcon />} link={`${process.env?.REACT_APP_MEDIUM_LINK}`} />
                                                    <FollowUsSingle image={<FacebookIcon />} link={`${process.env?.REACT_APP_FACEBOOK_LINK}`} />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Row>

                                    <Row>
                                        <Flex>
                                            <Flex width="30%"></Flex>
                                            <Flex justifyContent="center" alignItems="center" >
                                                <Flex
                                                    width="100%"
                                                    maxWidth="40rem"
                                                    justifyContent="flex-start"
                                                >
                                                    <FollowUsSingle image={<TwitterIcon />} link={`${process.env?.REACT_APP_TWITTER_LINK}`} />
                                                    <FollowUsSingle image={<TelegramIcon />} link={`${process.env?.REACT_APP_TELEGRAM_LINK}`} />
                                                    <FollowUsSingle image={<YoutubeIcon />} link={`${process.env?.REACT_APP_YOUTUBE_LINK}`} />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Row>
                                </Box>
                            </Flex>
                        </Col>

                        {/* ContactUs Component Call */}
                        <ContactUs />

                    </Row>

                    {/* Bottom Footer Component Call */}
                    <FooterBottom />

                </ContainerRow >
            </Box >
        </>
    );
};
export default Footer;