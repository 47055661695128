import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const Sms: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19.8333 23.9166H8.16659C4.66659 23.9166 2.33325 22.1666 2.33325 18.0833V9.91665C2.33325 5.83331 4.66659 4.08331 8.16659 4.08331H19.8333C23.3333 4.08331 25.6666 5.83331 25.6666 9.91665V18.0833C25.6666 22.1666 23.3333 23.9166 19.8333 23.9166Z" stroke="#CD63FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
            <path d="M19.8334 10.5L16.1817 13.4167C14.9801 14.3733 13.0084 14.3733 11.8067 13.4167L8.16675 10.5" stroke="#CD63FF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" fill="none" />
        </Svg>
    )
}
export default Sms
