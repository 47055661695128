import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const EyeIconHidden: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M3 3L21 21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M10.584 10.5879C10.2087 10.9629 9.99778 11.4716 9.99759 12.0022C9.99741 12.5327 10.208 13.0416 10.583 13.4169C10.958 13.7922 11.4668 14.0031 11.9973 14.0033C12.5278 14.0035 13.0367 13.7929 13.412 13.4179" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M17.357 17.3491C15.726 18.4491 13.942 19.0001 12 19.0001C8 19.0001 4.667 16.6671 2 12.0001C3.369 9.60506 4.913 7.82506 6.632 6.65906M9.363 5.36506C10.2204 5.11978 11.1082 4.9969 12 5.00006C16 5.00006 19.333 7.33306 22 12.0001C21.222 13.3611 20.388 14.5241 19.497 15.4881L9.363 5.36506Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none' />
        </Svg>
    )
}
export default EyeIconHidden
