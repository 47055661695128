import axios from "state/axios/interceptors";
import { REACT_APP_APIS } from "config/constants/endpoints";
import qs from "qs";
import { IUser } from "../types";

/**
 * Fetch Projects to list.
 */
export const forgotPasswordUser = async (email: string): Promise<IUser> => {
	try {
		const { data } = await axios({
			data: qs.stringify({
				email,
			}),
			url: `${REACT_APP_APIS}/user/forget`,
			headers: { "content-type": "application/x-www-form-urlencoded" },
			method: "POST",
		});
		console.info(data, "---- POST Express");
		return {
			...data,
		};
	} catch (error) {
		console.error("Failed to post user express interest", error);
		throw error;
	}
};
