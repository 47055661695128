import axios from "state/axios/interceptors";
import { REACT_APP_APIS } from "config/constants/endpoints";
import qs from "qs";

export interface IUserPasswordChange {
	oldPassword: string;
	newPassword: string;
	confirmPassword?: string;
}

/**
 * Fetch Projects to list.
 */
export const userChangePassword = async (
	oldPassword,
	newPassword
): Promise<IUserPasswordChange> => {
	try {
		const { data } = await axios({
			data: qs.stringify({
				oldPassword,
				newPassword,
			}),
			url: `${REACT_APP_APIS}/user/change-password`,
			headers: {
				jwt: localStorage.getItem("jwt"),
			},
			method: "POST",
		});
		console.info(data, "---- POST Express");
		return {
			...data.user,
		};
	} catch (error) {
		console.error("Failed to post user express interest", error);
		throw error;
	}
};
