import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ArrowLeft: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="8" height="14" viewBox="0 0 8 14" {...props}>
      <path d="M1 13L7 7L1 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    </Svg>
  );
};

export default ArrowLeft;
