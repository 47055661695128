import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const YoutubeIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19.8333 5.83334H8.16667C5.58934 5.83334 3.5 7.92268 3.5 10.5V17.5C3.5 20.0773 5.58934 22.1667 8.16667 22.1667H19.8333C22.4107 22.1667 24.5 20.0773 24.5 17.5V10.5C24.5 7.92268 22.4107 5.83334 19.8333 5.83334Z" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
            <path d="M11.6666 10.5L17.5 14L11.6666 17.5V10.5Z" fill="#CD63FF" />
        </Svg>
    )
}
export default YoutubeIcon
