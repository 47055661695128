import React from 'react'
import Svg from "../Svg";
import { SvgProps } from "../types";

const TwitterIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M25.6667 4.67835C24.5 5.25002 23.3567 5.48218 22.1667 5.83335C20.8589 4.35752 18.9199 4.27585 17.0567 4.97352C15.1935 5.67118 13.9732 7.37685 14 9.33335V10.5C10.2142 10.5968 6.84254 8.87252 4.66671 5.83335C4.66671 5.83335 -0.212293 14.5052 9.33337 18.6667C7.14937 20.1215 4.97121 21.1027 2.33337 21C6.19271 23.1035 10.3985 23.8268 14.0397 22.7698C18.2164 21.5565 21.6487 18.4263 22.9659 13.7375C23.3588 12.3115 23.5539 10.8382 23.5457 9.35902C23.5434 9.06852 25.3074 6.12502 25.6667 4.67718V4.67835Z" stroke="#CD63FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill='none' />
        </Svg>
    )
}
export default TwitterIcon
